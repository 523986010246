<docs>
## Notes
Displays a form to populate a component model.
Watches the "editData" property and populates the component form if editing a component.

Displays a mass calculator depending on form model.
ComponentForm implements the FlatMassCalculator, PolybagMassCalculator, BlisterMassCalculator, PaperMassCalculator, and CorrugatedMassCalculator components. 

ComponentForm emits "hideComponentForm" to hide the form, this also resets the model.
ComponentForm emits "addDesignComponent" to add a component to the parent component.
ComponentForm emits "addDesignComponentToLibrary" to add a component to both the parent design and component library.
</docs>

<template>
  <div class="card-body">
    <div class="row">
      <div class="col-md-6 col-lg-5">
        <div v-bind:class="['form-group', {'has-error':errors.has('name')}]">
          <label>Component Name<span class="text-danger">*</span></label>
          <input type="text" class="form-control"
            name="name" 
            v-model="model.name" 
            v-validate="'required'"
            v-on:keypress="refreshSelects"
            v-on:input="refreshSelects"
            placeholder="Enter a name">
          <span class="form-text" v-if="errors.has('name')" v-cloak>Name is required.</span>
        </div>
        <div v-bind:class="['form-group', {'has-error':errors.has('component_sub_type_id')}]">
          <label>Component Sub Type<span class="text-danger">*</span></label>
          <select class="form-control selectpicker"
            name="component_sub_type_id" 
            v-model="model.component_sub_type_id"
            v-validate="'required'"
            v-on:change="handleUpdateSubType"
            v-bind:disabled="!model.name">
            <option :value="null" disabled>Select component type</option>
            <option v-for="(option, key) in options.sub_types" :value="option.id" :data-content="optionIcon(option)">
              {{ option.name }}
            </option>
          </select>
          <span class="form-text" v-if="errors.has('component_sub_type_id')" v-cloak>Sub Type is required.</span>
        </div>
        <div v-bind:class="['form-group', {'has-error':errors.has('material_id')}]">
          <label>Material<span class="text-danger">*</span></label>
          <select class="form-control selectpicker" 
            name="material_id" 
            v-model="model.material_id"
            v-validate="'required'"
            v-bind:disabled="!model.name || !model.component_sub_type_id">
            <option :value="null" disabled>Material</option>
            <option v-for="(option, key) in options.materials" :value="option.id">{{ option.name }}</option>
          </select>
          <span class="form-text" v-if="errors.has('material_id')" v-cloak>Material is required.</span>
        </div>
        <div v-bind:class="['form-group', {'has-error':errors.has('material_conversion_id')}]">
          <label>Conversion<span class="text-danger">*</span></label>
          <select class="form-control selectpicker" 
            name="material_conversion_id" 
            v-model="model.material_conversion_id"
            v-validate="'required'"
            v-bind:disabled="!model.name || !model.component_sub_type_id">
            <option :value="null" disabled>Conversion</option>
            <option v-for="(option, key) in options.conversion" :value="option.id">{{ option.name }}</option>
          </select>
          <span class="form-text" v-if="errors.has('material_conversion_id')" v-cloak>Conversion is required.</span>
        </div>
        <div v-bind:class="['form-group', {'has-error':errors.has('quantity')}]">
          <label>Quantity<span class="text-danger">*</span></label><br>
          <input class="form-control input-narrow" type="number" 
            min="1" max="15" 
            v-model="model.quantity"
            v-validate="'required'"
            v-bind:disabled="!model.name || !model.component_sub_type_id">
          <span class="form-text" v-if="errors.has('quantity')" v-cloak>Quantity is required.</span>
        </div>
        <div v-if="currentView === 'paper'" class="form-group">
          <label>Certification</label>
          <select class="form-control selectpicker"
            name="component_certification_type_id" 
            v-model="model.component_certification_type_id"
            v-bind:disabled="!model.name || !model.component_sub_type_id"
          >
            <option :value="null" disabled>Select Certification Type</option>
            <option v-for="(option, key) in options.certification" :value="option.id">{{ option.name }}</option>
          </select>
        </div>
        <div class="form-group">
          <label>Recycled or Bio Content</label><br>
          <input type="number" class="form-control input-narrow" 
            min="0" max="100"
            name="recycled_or_bio_content" 
            v-model="model.recycled_or_bio_content"
            v-bind:disabled="!model.name || !model.component_sub_type_id">
            <span>%</span>
        </div>
        <div v-bind:class="['form-group', {'has-error':errors.has('mass_g')}]">
          <label>Mass<span class="text-danger">*</span></label><br>
          <input type="text" maxlength="12" class="form-control input-narrow" 
            name="mass_g" 
            v-model="model.mass_g"
            v-validate="'required:true|max:12|min_value:0.001'"
            v-on:change="handleMassInput"
            v-bind:disabled="!model.name || !model.component_sub_type_id">
            <span class="text-muted">grams</span>
            <span class="form-text" v-if="errors.has('mass_g:required')" v-cloak>Mass is required.</span>
            <span class="form-text" v-if="errors.has('mass_g:max')" v-cloak>Input limit is 12 characters.</span>
            <span class="form-text" v-if="errors.has('mass_g:min_value')" v-cloak>Mass must not be zero and must be more than 0.001g.</span>
        </div>
        <div class="form-group">
          <label>Is this component also used for storage of the product?</label>
          <div>
            <div class="form-check-inline">
              <label class="form-check-label"><input class="form-check-input" type="radio" name="used_for_storage" :value="1" v-model="model.used_for_storage" v-bind:disabled="!model.name || !model.component_sub_type_id"> Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label"><input class="form-check-input" type="radio" name="used_for_storage" :value="0" v-model="model.used_for_storage" v-bind:disabled="!model.name || !model.component_sub_type_id"> No</label>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-md-6 col-lg-5">
        <flat-mass-calculator 
          v-if="model.calc === 'flat'"
          :calculatorData="model.calculator_data"
          :materialId="model.material_id"
          v-on:invalidMaterialId="handleInvalidMaterialId"
          v-on:useCalculatedMass="handleUseCalculatedMass">
        </flat-mass-calculator>

        <polybag-mass-calculator 
          v-if="model.calc === 'polybag'"
          :calculatorData="model.calculator_data"
          :materialId="model.material_id"
          v-on:invalidMaterialId="handleInvalidMaterialId"
          v-on:useCalculatedMass="handleUseCalculatedMass">
        </polybag-mass-calculator>

        <blister-mass-calculator 
          v-if="model.calc === 'blister'"
          :calculatorData="model.calculator_data"
          :materialId="model.material_id"
          v-on:invalidMaterialId="handleInvalidMaterialId"
          v-on:useCalculatedMass="handleUseCalculatedMass">
        </blister-mass-calculator>
        <paper-mass-calculator 
          v-if="model.calc === 'paper'"
          :calculatorData="model.calculator_data"
          v-on:useCalculatedMass="handleUseCalculatedMass">
        </paper-mass-calculator>

        <corrugated-mass-calculator 
          v-if="model.calc === 'corrugated'"
          :calculatorData="model.calculator_data"
          v-on:useCalculatedMass="handleUseCalculatedMass">
        </corrugated-mass-calculator>
      </div>
      
      <div class="col-12 mt-3">
        <button type="button" class="btn btn-primary" @click.prevent="addDesignComponent">
         <span v-if="editing && !editLoading">Update Component</span>
         <span v-if="!editing && !editLoading">Add Component</span>
         <span v-if="editLoading">Saving...</span>
        </button>
        <button type="button" class="btn btn-link" @click.prevent="reset">
         Cancel Component
        </button>
        <div class="form-group">
          <div class="form-check form-check-inline">
            <label class="form-check-label" style="display:block;margin:10px 0 0 1px">
              <input class="form-check-input" type="checkbox" :value="true" v-model="model.library"> Save to Library
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Event } from 'vue-tables-2';
import * as constants from './../constants-common.js';
import FlatMassCalculator from './FlatMassCalculator.vue';
import PolybagMassCalculator from './PolybagMassCalculator.vue';
import BlisterMassCalculator from './BlisterMassCalculator.vue';
import PaperMassCalculator from './PaperMassCalculator.vue';
import CorrugatedMassCalculator from './CorrugatedMassCalculator.vue';

export default {
  name: 'component-form',

  props: [
    'currentView', // [paper|plastic|other]
    'editData', 
    'editLoading'
  ],
  
  data () {
    return {
      // Default model for component
      defaultModel: {
        name: null,
        component_sub_type_id: null,
        material_id: null,
        material_conversion_id: null,
        quantity: 1,
        component_certification_type_id: null,
        recycled_or_bio_content: 0,
        mass_g: 0,
        mass_kg: 0,
        used_for_storage: 0,
        calculated: false,
        calculator_data: null,
        
        // determines which calculator to display
        // values [flat|polybag|blister|paper|corrugated]
        calc: false,

        // determines if this should be added to the
        // component library when saved
        library: false,
      },

      // our data model, updated via user inputs
      model: this.defaultModel,

      // flag for edit mode
      editing: false,

      // our dropdown options for the current view
      options: {
        sub_types: constants.SUB_TYPES[this.currentView],
        materials: constants.MATERIALS[this.currentView],
        conversion: constants.CONVERSION[this.currentView],
        certification: constants.CERTIFICATION
      }
    };
  },

  watch: {
    // check for edit data and update model accordingly
    'editData': function() {
      this.checkForEdit();
    },

    // convert grams to kilograms and store value
    'model.mass_g': function(){
      this.model.mass_kg = Number(this.round6(this.model.mass_g/1000));
    }
  },

  methods: {
    editDesignComponent(obj){
      this.model = obj;
      this.refreshSelects();
    },

     // Click handler for "add component" button. Emits addComponent with a this.model payload.
    addDesignComponent(){
      this.$validator.validateAll().then((result) => {
        if(result) {
          // valid
          //this.loading = true; // could maybe set to value of this.$parent.componentSaving
          this.$emit('addDesignComponent', this.model);
        } else {
          // invalid
          //this.loading = false;
          $('form#design-details').find('[aria-invalid="true"]').first().focus();
        }
      });
    },

    // Resets the model to defaul properties, emits "resetComponentForm" to parent.
    reset(){
      this.$emit('hideComponentForm');
      this.getModel();
    },

    // Reset our form's model back to default
    getModel(){
      this.model = { ...this.defaultModel };
    },

    checkForEdit(){
      if(this.editData && this.editData.constructor === Object && Object.keys(this.editData).length !== 0) {
        this.model = { ...this.editData };
        this.refreshSelects();
        this.checkCalculatorDisplay();
        this.updateFormatting();
        this.editing = true;   
      } else {
        this.editing = false;
      }
    },

    handleMassInput(e){
      this.model.calculated = false;
    },

    handleUseCalculatedMass(obj){
      if(obj.hasOwnProperty('mass')) {
        this.model.mass_g = this.round2(obj.mass);
        this.model.calculated = true;
        this.model.calculator_data = obj;
      }
    },

    handleInvalidMaterialId(e){
      // trigger the validation message for Material
      this.$validator.validate('material_id');
    },

    handleUpdateSubType() {
      let option = this.options.sub_types.find(obj => obj.id === this.model.component_sub_type_id);

      this.model.material_id = option.material ? option.material : null;
      this.model.material_conversion_id = option.conversion ? option.conversion : null;
      this.model.calc = option.calc ? option.calc : null;

      this.refreshSelects();
    },

    checkCalculatorDisplay() {
      let option = this.options.sub_types.find(obj => obj.id === this.model.component_sub_type_id);
      this.model.calc = option.calc ? option.calc : null;
    },

    refreshSelects(){
      // bootstrap select - yes this is gross
      this.$nextTick(function () {
        $('.selectpicker').selectpicker('refresh');
      });
    },

    updateFormatting(){
      if(this.model.mass_g) {
        this.model.mass_g = this.round6(this.model.mass_g);
      }
    },

    round2(input){
      return Math.round(input * 100) / 100;
    },

    round6(input){
      return Math.round(input * 1000000) / 1000000;
    },

    optionIcon(option) {
      return option.calc ? option.name+' &nbsp;&nbsp; <i class="fa fa-calculator" v-if="option.calc"></i>' : option.name;
    }
  },

  mounted(){
    this.$nextTick(function () {
      // bootstrap select
      $('.selectpicker').selectpicker();
    });
  },

  beforeMount(){
    this.getModel();
    this.checkForEdit();
  },

  components:{
    FlatMassCalculator,
    PolybagMassCalculator,
    BlisterMassCalculator,
    PaperMassCalculator,
    CorrugatedMassCalculator
  }
}
</script>