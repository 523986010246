<docs>
## Notes
Displays a simple form under a selected row within the DesignsTable component's v-client-table.
DesignsTableForm can emit 'updateDesign' and 'copyDesign' events.
DesignsTableForm "save" button will hide/show only if properties have changes and are present.
</docs>

<template>
  <div v-if="rowCanEdit" class="table-form">
    <div class="pull-left form-inline">
      <div class="form-group">
        <input class="form-control" v-model="name" placeholder="Project name">
      </div>
      <div v-if="rowCanSetChosen" class="form-group">
        <div class="form-check">
          <label class="form-check-label">
            <input class="form-check-input" type="checkbox" v-model="chosen"> Set as Production design
          </label>
        </div>
      </div>
      <button type="button" class="btn btn-primary" v-show="rowCanSave" :disabled="!rowCanSave" @click="updateRow"><i class="fa fa-save fa-fw"></i> Save</button>
    </div>
    <div class="pull-right">
      <button type="button" class="btn btn-light" @click="copyRow"><i class="fa fa-files-o fa-fw"></i> Copy</button>
      <button type="button" v-if="rowCanEdit" class="btn btn-danger" @click="deleteRow"><i class="fa fa-trash fa-fw"></i> Delete</button>
    </div>
  </div>
</template>

<script>
import { Event } from 'vue-tables-2';

export default {
  name: 'designs-table-form',
  
  props: [
    'data', 
    'index'
  ],
  
  data () {
    return {
      name: this.data.name,
      chosen: this.data.chosen,
      project_id: this.data.project_id
    }
  },

  computed: {
    rowCanSave() {
     return this.name != '' && (this.name != this.data.name || this.chosen != this.data.chosen);
    },

    rowCanSetChosen() {
      return this.data.status === 'Complete' || this.data.status === 'Production';
    },

    rowCanEdit() {
      return this.data.user_is_owner;
    }
  },

  methods: {
    copyRow() {
       Event.$emit('copyDesign', this.data.id);
    },

    updateRow() {
      Event.$emit('updateDesign', {id: this.data.id, name: this.name, chosen: this.chosen, project_id: this.project_id});
    },

    deleteRow() {
      if(window.confirm(`Are you sure that you want to delete ${this.data.name}?`)){
        Event.$emit('deleteDesign', this.data.id);
      }
    }
  }
}
</script>