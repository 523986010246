<docs>
## Notes
Displays a list of existing components available to the user. 
ComponentLibrary emits a "componentSelected" when "select" is clicked, with a payload of component ids.
ComponentLibrary can remove a component or multiple components from the library.
</docs>

<template>
  <div id="component-library-table" class="card-body">
    <v-client-table :data="tableData" :columns="columns" :options="options" name="component-library-table" ref="componentLibrary">
      
      <template slot="beforeFilter">
        <div class="pull-left">
          <button type="button" class="btn btn-primary" v-on:click.prevent="selectComponents">Add</button>
          <button type="button" class="btn btn-primary" v-on:click.prevent="handleRemoveComponents">Delete</button>
        </div>
      </template>
      
      <template slot="h__select" slot-scope="props">
          <input type="checkbox" v-model="allMarked" v-on:change="toggleAll()">
      </template>

      <template slot="select" slot-scope="props">
        <input type="checkbox" :value="props.row.id" v-model="markedRows" v-on:change="unmarkAll()">
      </template>

      <template slot="h__recycled_or_bio_content" slot-scope="props">
        Recycled and <span class="nobr">Bio-Content (%)</span>
      </template>

      <template slot="recycled_or_bio_content" slot-scope="props">
        {{ round2(props.row.recycled_or_bio_content) }}
      </template>

      <template slot="h__mass_g" slot-scope="props">
        <span class="nobr">Mass (g)</span>
      </template>

      <template slot="mass_g" slot-scope="props">
        {{ round2(props.row.mass_g) }}
      </template>
    </v-client-table>
  </div>
</template>

<script>
import { HTTP } from './../http-common';
import { Event } from 'vue-tables-2';

export default {
  name: 'component-library',

  data () {
    return {
      // Array of selected component id's from the table
      markedRows: [],
      
      // all rows are marked - true/false
      allMarked: false,

      // v-client-table table rows
      tableData: [],

      //v-client-table column identifiers
      columns: ['select', 'id', 'name', 'material_conversion_entry_type', 'component_sub_type', 'material', 'material_conversion', 'quantity', 'component_certification_type', 'recycled_or_bio_content', 'mass_g'],

      // v-client-table options
      options: {
        headings: {
          select: false,
          id: 'ID',
          name: 'Component Name',
          material_conversion_entry_type: 'Component Type',
          component_sub_type: 'Sub-Type',
          material: 'Material Type',
          material_conversion: 'Conversion',
          component_certification_type: 'Certification Type',
          recycled_or_bio_content: 'Recycled or Bio-Content (%)',
          mass_g: 'Mass (g)'
        },
        filterable: true,
        sortable: ['id', 'name', 'material_conversion_entry_type', 'component_sub_type', 'material', 'material_conversion', 'quantity', 'component_certification_type', 'recycled_or_bio_content', 'mass_g'],
        skin: 'table table-striped',
        sortIcon: {base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort'},
        texts:{
          filterPlaceholder: 'Search...'
        },
        pagination: {align: 'right'}
      }
    }
  },

  methods: {

    // Uncheck all rows.
    unmarkAll() {
      this.allMarked = false;
    },
    
    // Mark all the rows selected.
    toggleAll() {
      this.markedRows = this.allMarked ? this.$refs.componentLibrary.data.map(row=>row.id) : [];
    },

    // Helper method access by parent to reset selection.
    resetSelected() {
      this.unmarkAll;
      this.markedRows = [];      
    },

    // Fetch our components
    fetchLibraryComponents(){
      HTTP.get('/api/library')
        .then(response => {
          this.tableData = response.data;

        })
        .catch(e => {
          alert(e);
        });
    },

    // Click handler for slect button. Emits componentSelected with array of component ids as payload.
    selectComponents(){
      if(this.tableData.length && this.markedRows.length)  {
        this.$emit('componentSelected', this.markedRows);
      }
    },

    // Click handler to remove a component from the library.
    handleRemoveComponents(){
      const plural = (this.markedRows.length === 1) ? 'component' : 'components';
      if(window.confirm(`Do you want to remove the selected ${plural}? This will remove the ${plural} from the library for the entire company.`)) this.removeComponents();
    },

    // Removes a component from the library.
    removeComponents(){
      if(window.confirm('Delete the selected components from the library?') && this.markedRows.length){
        // @data - an axios request config object with an array of ids to delete
        const data = {'data': {ids:this.markedRows}};
        HTTP.delete('/api/library', data)
        .then(response => {
          this.tableData = this.tableData.filter((obj) => this.markedRows.indexOf(obj.id) === -1);
        })
        .catch(e => {
          alert(e);
        });
      }
    },

    round2(input){
      return Math.round(input * 100) / 100;
    },
  },
  
  // fecth our library components. Fired each time the form is displayed via "v-if"
  beforeMount(){
    this.fetchLibraryComponents();
  },
}
</script>

<style>
  @media (min-width: 576px) {
    #component-library-table .VueTables__search {
      -webkit-box-pack: justify !important;
      -webkit-justify-content: space-between !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
  }
</style>