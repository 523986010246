<docs>
## Notes
Displays a blister mass calculator.
Emits "updateMass" event with mass value.
Converts units based on user prefs.
Repopulates inputs with stored data if prop is passed.

Formula: Calculater v1.3.xlxs

  length_cm * width_cm * thickness * 0.1 * lookup(density)
    ~ or ~
  surface_area_cm  * thickness * 0.1 * lookup(density) * thermoform_loss_rate

    thickness - conversion mm to mil
    density - lookup based on material PET or PVC
    thermoform loss rate (if surface area)
</docs>

<template>
  <div class="card card-info mt-3">
    <div class="card-header">
      <h3 class="card-title">Blister Calculator</h3>
    </div>
    <div class="card-body">
      <p v-if="thickness_unit === 'mm'" class="alert alert-info">
        Average values for blister thickness is 0.075 mm – 0.80 mm
      </p>
      <p  v-if="thickness_unit === 'mil'" class="alert alert-info">
        Average values for blister thickness is 3 mil to 30 mil
      </p>
      <div class="form-group">
        <label>Entry Type</label><br>
        <select class="form-control selectpicker select-narrow" v-model="type">
          <option value="dimensions">Dimensions</option>
          <option value="surface_area">Surface Area</option>
        </select> 
      </div>
      <div v-if="type !== 'surface_area'">
        <div class="form-group">
          <label>Length</label><br>
          <input type="number" class="form-control input-narrow" name="length" v-model="length">
          <span class="text-muted" v-if="user.units == 'in'">inches</span>
          <span class="text-muted" v-if="user.units == 'cm'">cm</span>
        </div>
        <div class="form-group">
          <label>Width</label><br>
          <input type="number" class="form-control input-narrow" name="width" v-model="width">
          <span class="text-muted" v-if="user.units == 'in'">inches</span>
          <span class="text-muted" v-if="user.units == 'cm'">cm</span>
        </div>
      </div>

      <div v-if="type === 'surface_area'" class="form-group">
        <label>Surface Area</label><br>
        <input type="number" class="form-control input-narrow" name="surface_area" v-model="surface_area">
        <span class="text-muted" v-if="user.units == 'in'">inches<sup>2</sup></span>
          <span class="text-muted" v-if="user.units == 'cm'">cm<sup>2</sup></span>
      </div>
      
      <div class="form-group">
        <label>Thickness</label><br>
        <input type="number" class="form-control input-narrow" name="thickness" v-model="thickness">
        <select class="form-control selectpicker select-narrow" v-model="thickness_unit">
          <option>mil</option>
          <option>mm</option>
        </select> 
      </div>
      <div class="form-group">
        <label>Mass</label><br>
        <input type="number" class="form-control input-narrow" name="mass" v-model="mass" readonly>
        <span class="text-muted">grams</span>
      </div>

      <button type="button" class="btn btn-info btn-sm" @click.prevent="computeMass" v-bind:disabled="!isValid">
        Calculate mass
      </button>
      <button type="button" class="btn btn-info btn-sm" @click.prevent="updateMass" v-bind:disabled="!isValid">
        Use this mass
      </button>
    </div>
  </div>
</template> 

<script>
import * as constants from './../constants-common.js';

export default {
  name: 'blister-mass-calculator',

  props: ['calculatorData', 'materialId'],

  data () {
     return {
      length: 0,
      width: 0,
      surface_area: 0,
      type: 'dimensions',
      thickness: null,
      thickness_unit: 'mil',
      mass: null,
      options: {
        material_density: constants.CALCULATOR.material_density 
      }
     }
  },

  computed: {
    isValid: function() {
      if(this.type === 'surface_area') {
        return (this.surface_area > 0 && this.thickness > 0);
      } else {
        return (this.length > 0 && this.width > 0 && this.thickness > 0);
      }
    }
  },

  methods: {
    computeMass(){
      let length = this.length;
      let width  = this.width;
      let surface_area = this.surface_area;
      let thickness = this.thickness;
      let density = this.lookup(this.options.material_density, 'id', this.materialId);

      if(this.user.units === 'in') {
        // convert in to cm for calculation
        length = this.round2(this.length * 2.54);
        width  = this.round2(this.width * 2.54);
        surface_area = this.round2(this.surface_area * constants.CALCULATOR.conversions.in2tocm2);
      }

      if(this.thickness_unit === 'mil') {
        // convert mil to mm for calculation
        thickness = this.thickness * constants.CALCULATOR.conversions.miltomm;
      }

      if(this.type === 'surface_area') {
        this.mass = surface_area*thickness*0.1*density*constants.CALCULATOR.conversions.blisterloss;
      } else {
        this.mass = length*width*thickness*0.1*density;
      }

      this.mass = Number(this.round2(this.mass));
    },

    updateMass(){
      this.$emit('useCalculatedMass', this.getCalculatorData());
    },

    getCalculatorData(){
      return {
        length: (this.surface_area) ? 0 : this.length,
        width: (this.surface_area) ? 0 : this.width,
        surface_area: this.surface_area,
        type: this.type,
        thickness: this.thickness,
        thickness_unit: this.thickness_unit,
        mass: this.mass,
        units: this.user.units
      }
    },

    setCalculatorData(){
      let obj = this.calculatorData;

      if(obj === null || typeof obj !== 'object') {
        return;
      }

      // restore calculated values, then check for conversions
      this.length = obj.length;
      this.width = obj.width;
      this.surface_area = obj.surface_area;
      this.type = obj.type;
      this.thickness = obj.thickness;
      this.thickness_unit = obj.thickness_unit;
      this.mass = obj.mass;

      if(obj.units == 'in' && this.user.units === 'cm') {
        // convert in to cm
        this.length = this.round2(obj.length * 2.54);
        this.width  = this.round2(obj.width * 2.54);
        surface_area = this.round2(this.surface_area * constants.CALCULATOR.conversions.cm2toin2);
      }

      if(obj.units == 'cm' && this.user.units === 'in') {
        // convert cm to in
        this.length = this.round2(obj.length/ 2.54);
        this.width  = this.round2(obj.width / 2.54);
        surface_area = this.round2(this.surface_area * constants.CALCULATOR.conversions.in2tocm2);
      }
    },

    checkMaterialId() {
      if(this.materialId === null) {
        // trigger validation feedback in parent component
        this.$emit('invalidMaterialId', {material:this.materialId});
      }
    },

    round2(input){
      return Math.round(input * 100) / 100;
    },

    lookup(optionsArray, key, val){
      let option = optionsArray.find(obj => obj[key] === val);
      return option.value;
    }
  },

  mounted(){
    this.$nextTick(function () {
      // bootstrap select
      $('.selectpicker').selectpicker();
    });

    // Make sure we have a valid material to lookup density
    this.checkMaterialId();
  },

  beforeMount(){
    this.setCalculatorData();
  }
}
</script>
