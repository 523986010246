'use strict';

var merge = require('merge');

module.exports = function (defaults, globalOptions, localOptions) {

     if (globalOptions) defaults = merge.recursive(defaults, globalOptions);

     localOptions = merge.recursive(defaults, localOptions);

     return localOptions;
};