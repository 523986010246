<docs>
## Notes
Displays a list of all existing designs available to the user to add to the current project.
Selecting an existing designs clones the design, including any components.
Users can filter and toggle favorites from this view just like the main Design Tables.
</docs>

<template>
  <div id="existing-designs-table">

    <div class="text-center card card-loading" v-if="loading">Loading...</div>

    <v-client-table :data="tableData" :columns="columns" :options="options" name="existing-designs-table" ref="designsTable" v-if="!loading">

      <template slot="afterFilter" >
        <div class="form-group VueTables__search-filter">
          <label class="filter-label">Showing:</label>
          <select class="selectpicker" v-on:change="filterSelect($event)" v-model="filterSelected">
            <option value="my-designs">My Designs</option>
            <option value="favorites">Favorite Designs</option>
            <option data-divider="true"></option>
            <option value="all">All Designs</option>
          </select>
        </div>
      </template>

      <template slot="favorite" slot-scope="props">
        <a v-if="props.row.favorite" v-on:click.prevent="favoriteDesign({id: props.row.id, favorite: false})">
          <i class="fa fa-star favorite favorite-active" aria-hidden="true"></i>
        </a>
        <a v-if="!props.row.favorite" v-on:click.prevent="favoriteDesign({id: props.row.id, favorite: true})">
          <i class="fa fa-star-o favorite" aria-hidden="true"></i>
        </a>
      </template>
      
      <template slot="select" slot-scope="props">
        <button type="button" class="btn btn-light btn-sm" v-on:click="selectDesign(props.row.id)">Select</button>
      </template>

      <template slot="spi_score_total" slot-scope="props">
        <a 
          v-if="props.row.spi_score_total" 
          :href="`/projects/${props.row.project_id}/scores/${props.row.id}`"
          v-bind:class="['badge', 'badge-'+scoreKey(props.row)]">
          <strong>{{props.row.spi_score_total}}</strong>
          {{ scoreKey(props.row)+' norm' }}
        </a>
      </template>

      <template slot="status" slot-scope="props">
        <span v-bind:class="['badge', 'badge-'+props.row.status.toLowerCase() ]">{{props.row.status}}</span>
      </template>

    </v-client-table>
  </div>
</template>

<script>
import { HTTP } from './../http-common';
import { Event } from 'vue-tables-2';

export default {
  name: 'existing-designs-table',

  props: {
    // the id of the project
    // @example - this.projectId
    projectId: {
      type: Number,
      default: null,
      required: true
    }
  },

  data () {
    return {
      loading: false,
      filterSelected: 'all',
      markedRows: [],
      allMarked:false,
      
      columns: ['select', 'favorite', 'id', 'name', 'details', 'design_format', 'spi_class', 'spi_score_total', 'created_by_email', 'status'],

      tableData: [],

      options: {
        headings: {
          select: '',
          id: 'ID',
          name: 'Project Name',
          details: 'Design Name',
          design_format: 'Design Format',
          pi_class: 'SPI Class',
          spi_score_total: 'SPI Score',
          created_by_email: 'Created By',
          status: 'Design Status'
        },
        sortable: ['favorite', 'name', 'details', 'design_format', 'spi_class', 'spi_score_total', 'created_by_email', 'status'],
        skin: 'table table-striped',
        sortIcon: {base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort'},
        pagination: {align: 'right'},
        texts:{
          filterPlaceholder: 'Search...'
        },
        customFilters: [
          {
            name: 'owner',
            callback: function (row, query) {
                if(query === true) {
                  return row.user_is_owner === true;
                } else {
                  return true;
                }
                
            }
          },
          {
            name: 'favorites',
            callback: function (row, query) {
                if(query === true) {
                  return row.favorite === true;
                } else {
                  return true;
                }
                
            }
          },
        ],
        initFilters: {
          owner: false,
          favorites: false
        },
        orderBy: {
          column: 'updated_at',
          ascending: false
        },
        multiSorting: {
          'updated_at' : [
            {
              column: 'id',
              matchDir: true
            }
          ]
        }
      }
    }
  },

  methods: {
    // Fetch our designs
    fetchDesigns(){
      this.loading = true;

      HTTP.get('/api/designs')
        .then(response => {
          if(Array.isArray(response.data)){
            this.tableData = response.data;
            this.loading = false;
          } else {
            throw new Error('DesignTable Component - fetchDesigns - response.data is not an array.');
            this.loading = false;
          }
        })
        .catch(e => {
          alert(e);
          this.loading = false;
        });
    },

    // Favorite a design by ID
    //  @param {obj} obj - An object with updated properties
    //  @param {string} obj.id - A design id.
    //  @param {string} obj.favorite - The favorite state of design.
    favoriteDesign(obj){
      if(obj.constructor === Object && Object.keys(obj).length !== 0)
      { 
        const index = this.tableData.findIndex(function (row) { return row.id === obj.id; });

        HTTP.get(`/api/favor/${obj.id}`)
        .then(response => {
          if(response.data.status == 'favored' || response.data.status == 're-favored') {
            this.tableData[index].favorite = true;
          } else {
            this.tableData[index].favorite = false;
          }
        })
        .catch(e => {
          // The request failed, so reset the row to it's previous state.
          this.tableData[index].favorite = !obj.favorite;
          alert(e);
        });
      }
    },

    selectDesign(id) {
      if(id)
      {
        this.loading = true;

        const data = { 
          project_id: this.projectId
        };

        HTTP.post(`/api/designs/${id}/copy`, data)
        .then(response => {
          const clone = response.data;
          console.log(clone);
          if(clone.constructor === Object && Object.keys(clone).length !== 0){
            let designId = clone.id;
            let projectId = clone.project_id;
            let editUrl = `/projects/${projectId}/designs/${designId}?designId=${designId}`;
            window.location = editUrl;
          } else {
            throw new Error('Design Details - copyExistingDesign- response.data is empty or not an object.');
            this.loading = false;
          }
        })
        .catch(e => {
          alert(e);
          this.loading = false;
        });
      }
    },

    filterSelect(event){
      switch(this.filterSelected) {
        case 'my-designs':
          this.filterMyDesigns(true);
          this.filterFavoriteDesigns(false);
          break;
        case 'favorites':
          this.filterMyDesigns(false);
          this.filterFavoriteDesigns(true);
          break;
        case 'all':
        default:
          this.filterMyDesigns(false);
          this.filterFavoriteDesigns(false);
          break;
      }
    },

    filterMyDesigns(query){
      Event.$emit('vue-tables.existing-designs-table.filter::owner', query);
    },

    filterFavoriteDesigns(query){
      Event.$emit('vue-tables.existing-designs-table.filter::favorites', query);
    },

    scoreKey(obj) {
      if(!obj.spi_score_total) return '';
      return obj.spi_score_total < obj.range_low_norm ? 'below' 
           : obj.spi_score_total > obj.range_high_norm ? 'above' : 'meets';
    }
  },

  mounted() {
    this.$nextTick(function () {
      // bootstrap select
      $('.selectpicker').selectpicker();
    });
  },

  beforeMount(){
    this.fetchDesigns();
  },
}
</script>

<style>
  .VueTables .favorite {
    font-size: 18px;
    position: relative;
    top: 2px;
    color: #777;
    opacity: 1;
  }

  .VueTables .favorite-active {
    color: #fdd700;
  }
</style>