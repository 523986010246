<docs>
## Notes
This is the top level component for the Design Comparison screen.

This "page" component is simply a wrapper for displaying and formatting data.

## Baseline Calculations
For the number value percent of change the key is to make sure the input values
being calculated are the rounded same as the values being displayed rather than 
rounding the output of the raw numbers.

For input values that are percentages we simply subtract the formatted values.
</docs>

<template>
  <div id="design-compare">

    <!-- Overall SPI Scores -->
    <section class="compare-overall-scores">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Overall SPI Scores</th>
              <th v-for="design in designs">{{ design.name }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td v-for="design in designs">
                  <span v-bind:class="['badge', 'badge-'+scoreKey(design.design_score.total_score)]">
                    <strong>{{ design.design_score.total_score }}</strong>
                    {{ scoreKey(design.design_score.total_score)+' norm' }}
                  </span>
              </td>
            </tr>
            <tr>
              <td><i class="fa fa-leaf fa-fw text-success" aria-hidden="true"></i> <strong>Source Responsibly</strong></td>
              <td v-for="design in designs">{{ design.design_score.source_responsibly_score }}/100</td>
            </tr>
            <tr>
              <td><i class="fa fa-recycle fa-fw text-primary" aria-hidden="true"></i> <strong>Design for Recycling</strong></td>
              <td v-for="design in designs">{{ design.design_score.design_for_recycling_score }}/100</td>
            </tr>
            <tr>
              <td><i class="fa fa-tree fa-fw text-success" aria-hidden="true"></i> <strong>Optimize Resources</strong></td>
              <td v-for="design in designs">{{ design.design_score.optimize_resources_score }}/100</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section><!-- /compare-overall-scores -->

    <!-- SPI Score Reports -->
    <section class="compare-score-reports">
      <div class="card spi-report">
        <div class="card-body"> 
          <div class="score-progress">
         
            <div class="score-row" v-for="(design, index) in designs">

              <div class="score-progress-label">
                <span class="project-name">{{ design.name }}</span>
              </div><!-- /score-progress-label -->
              
              <div class="progress">
                <div class="progress-bar progress-bar-gradient" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;">

                  <!-- Printable -->
                  <svg width="100%" height="20px" viewBox="0 0 20 20" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <rect class="print-bar-gradient" x="0" y="0" width="20" height="20" fill="url(#Gradient-1)" />
                  </svg>

                  <div v-if="index == Object.keys(designs).length-1">              
                    <!-- The inline styles for "score-range" controls the industry norm range -->
                    <div class="score-range" v-bind:style="{left: range.low_norm+'%', right: 100-range.high_norm+'%'}">
                      <!-- Printable -->
                      <svg width="100%" height="20px" viewBox="0 0 20 20" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <rect x="0" y="0" width="20" height="20" fill="white" opacity="0.5" />
                      </svg>
                      <span class="range-number-start">{{ Math.round(range.low_norm) }}</span>
                      <span class="range-number-end">{{ Math.round(range.high_norm) }}</span>
                      <div class="range-legend">
                        <span>Industry Norm Range</span>
                      </div><!-- /range-legend -->
                    </div><!-- /score-range -->
                    <!-- The inline style for the "indicator-line" controls the score (0-100%) -->
                    <div class="indicator-line" v-bind:style="{left: design.design_score.total_score+'%'}">
                      <div class="range-popover popover condensed bs-popover-top"> 
                        <div class="arrow"></div> 
                        <div class="popover-body"> 
                          <span>Total Score:</span>
                          <span class="total-score">{{ design.design_score.total_score }}</span>
                        </div> 
                      </div><!-- /popover -->
                    </div>
                  </div>
                  <div v-else>
                    <!-- The inline styles for "score-range" controls the industry norm range -->
                    <div class="score-range" v-bind:style="{left: range.low_norm+'%', right: 100-range.high_norm+'%'}">
                      <!-- Printable -->
                      <svg width="100%" height="20px" viewBox="0 0 20 20" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <rect x="0" y="0" width="20" height="20" fill="white" opacity="0.5" />
                      </svg>
                    </div><!-- /score-range -->
                    <!-- The inline style for the "indicator-line" controls the score (0-100%) -->
                    <div class="indicator-line" v-bind:style="{left: design.design_score.total_score+'%'}">
                      <!-- Printable -->
                      <svg width="100%" height="24px" viewBox="0 0 2 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <rect x="0" y="0" width="2" height="24" fill="black" />
                      </svg>
                      <div class="range-popover popover condensed bs-popover-top"> 
                        <div class="arrow"></div> 
                        <div class="popover-body"> 
                          <span>Total Score:</span>
                          <span class="total-score">{{ design.design_score.total_score }}</span>
                        </div> 
                      </div><!-- /popover -->
                    </div>
                  </div>
                  
                </div><!-- /progress-bar -->   
              </div><!-- /progress --> 

            </div><!-- /v-for loop -->

          </div><!-- /score-progress -->
        </div><!-- /card-body -->
      </div><!-- /card -->
    </section><!-- /compare-score-reports -->

    <!-- Individual SPI Scores -->
    <section class="compare-individual-scores">
      <div class="card card-info">
        <div class="card-header">
          <h2 class="card-title">
            Individual SPI Scores
            <a v-on:click="$root.modal='individualScores'" data-toggle="modal" href="#modal" class="info"><i class="fa fa-question-circle"></i></a>
          </h2>
        </div>
        <div class="card-body">       
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th><small class="text-muted">All Scores are out of 100</small></th>
                  <th v-for="design in designs">{{ design.name }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="compare-head"><i class="fa fa-leaf fa-fw text-success" aria-hidden="true"></i> <strong>Source Responsibly</strong></td>
                  <td class="compare-head" v-for="(design, index) in designs">
                    <strong v-bind:class="compareScore(
                      design.design_score.source_responsibly_score, 
                      designs[0].design_score.source_responsibly_score)">
                      {{ design.design_score.source_responsibly_score }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td class="indent">Carbon Footprint</td>
                  <td v-for="(design, index) in designs">{{ round(design.design_score.climate_change_total_norm) }}</td>
                </tr>
                <tr>
                  <td class="indent">Materials Health</td>
                  <td v-for="(design, index) in designs">{{ round(design.design_score.human_health_total_norm) }}</td>
                </tr>
                <tr>
                  <td class="indent">Recycled, Certified, and Bio-Content</td>
                  <td v-for="(design, index) in designs">{{ round(design.design_score.rec_cert_bio_content_pkg*100) }}</td>
                </tr>
                <tr>
                  <td class="compare-head"><i class="fa fa-recycle fa-fw text-primary" aria-hidden="true"></i> <strong>Design for Recycling</strong></td>
                  <td class="compare-head" v-for="(design, index) in designs">
                    <strong v-bind:class="compareScore(
                      design.design_score.design_for_recycling_score, 
                      designs[0].design_score.design_for_recycling_score)">
                      {{ design.design_score.design_for_recycling_score }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td class="indent">Unique Materials</td>
                  <td v-for="(design, index) in designs">{{ round(design.design_score.dfr_materials) }}</td>
                </tr>
                <tr>
                  <td class="indent">Recyclibility</td>
                  <td v-for="(design, index) in designs">{{ round(design.design_score.wtl_rec_rate_pkg*100) }}</td>
                </tr>
                <tr>
                  <td class="indent">Design for Recycling - Questions</td>
                  <td v-for="(design, index) in designs">{{ round(design.design_score.dfr_questions_pkg) }}</td>
                </tr>
                <tr>
                  <td class="compare-head"><i class="fa fa-tree fa-fw text-success" aria-hidden="true"></i> <strong>Optimize Resources</strong></td>
                  <td class="compare-head" v-for="(design, index) in designs">
                    <strong v-bind:class="compareScore(
                      design.design_score.optimize_resources_score,
                      designs[0].design_score.optimize_resources_score)">{{ design.design_score.optimize_resources_score }}</strong>
                  </td>
                </tr>
                <tr>
                  <td class="indent">Cube Utilization</td>
                  <td v-for="(design, index) in designs">{{ round(design.design_score.cube2_norm) }}</td>
                </tr>
                <tr>
                  <td class="indent">Product to Package Ratio</td>
                  <td v-for="(design, index) in designs">{{ round(design.design_score.master_ratio_cube1_raw) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section><!-- /compare-individual-scores -->

    <!-- Industry Metrics -->
    <section class="compare-industry-metrics">
      <div class="card card-info">
        <div class="card-header">
          <h2 class="card-title">
            Industry Metrics
            <a v-on:click="$root.modal='industryMetrics'" data-toggle="modal" href="#modal" class="info"><i class="fa fa-question-circle"></i></a>
          </h2>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th v-for="(design, index) in designs">
                    {{ design.name }}
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <template v-for="(design, index) in designs">
                    <th v-if="index == 0"><strong class="badge badge-default">Baseline Design</strong></td>
                    <th v-else>
                      <div class="compare-split">
                        <div class="text-right"></div>
                        <div class="text-left">
                          <small class="text-muted">vs. Baseline</small>
                        </div>
                      </div>
                    </th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="compare-head"><i class="fa fa-leaf fa-fw text-success" aria-hidden="true"></i> <strong>Source Responsibly</strong></td>
                  <template v-for="(design, index) in designs">
                    <td class="compare-head"></td>
                  </template>
                </tr>
                <tr>
                  <td class="indent">Carbon Footprint <span class="units">(g CO<sub>2</sub>eq)</span></td>
                  <template v-for="(design, index) in designs">
                    <td v-if="index == 0">
                      {{ round2(design.design_score.cf_raw) }}
                    </td>
                    <td v-else>
                      <div class="compare-split">
                        <div class="text-right">{{ round2(design.design_score.cf_raw) }}</div>
                        <div class="text-left">
                          <span v-bind:class="compareChange(
                            round2(design.design_score.cf_raw), 
                            round2(designs[0].design_score.cf_raw))">
                            {{ round2(calculateChange(
                              round2(design.design_score.cf_raw), 
                              round2(designs[0].design_score.cf_raw))) }}%
                          </span>
                        </div>
                      </div>
                    </td>
                  </template>
                </tr>
                <tr>
                  <td class="indent">Materials Health <span class="units">(DALY * 10<sup>8</sup>)</span></td>
                  <template v-for="(design, index) in designs">
                    <td v-if="index == 0" >
                      {{ round2(design.design_score.mh_raw) }}
                    </td>
                    <td v-else>
                      <div class="compare-split">
                        <div class="text-right">
                          {{ round2(design.design_score.mh_raw) }}
                        </div>
                        <div class="text-left">
                          <span v-bind:class="compareChange(
                            design.design_score.mh_raw, 
                            designs[0].design_score.mh_raw)">
                            {{ round2(calculateChange(
                              round2(design.design_score.mh_raw), 
                              round2(designs[0].design_score.mh_raw))) }}%
                          </span>
                        </div>
                      </div>
                    </td>
                  </template>
                </tr>
                <tr>
                  <td class="indent">Recycled, Certified, and Bio-Content <span class="units">(%)</span></td>
                  <template v-for="(design, index) in designs">
                    <td v-if="index == 0" >
                      {{ round(design.design_score.rec_cert_bio_content_pkg*100) }}
                    </td>
                    <td v-else>
                      <div class="compare-split">
                        <div class="text-right">
                          {{ round(design.design_score.rec_cert_bio_content_pkg*100) }}
                        </div>
                        <div class="text-left">
                          <span v-bind:class="compareChange(
                            design.design_score.rec_cert_bio_content_pkg,
                            designs[0].design_score.rec_cert_bio_content_pkg,
                            true)">
                            {{ round((design.design_score.rec_cert_bio_content_pkg*100) - (designs[0].design_score.rec_cert_bio_content_pkg*100)) }}
                          </span>
                        </div>
                      </div>
                    </td>
                  </template>
                </tr>
                <tr>
                  <td class="compare-head"><i class="fa fa-recycle fa-fw text-primary" aria-hidden="true"></i> <strong>Design for Recycling</strong></td>
                  <td class="compare-head" v-for="design in designs"></td>
                </tr>
                <tr>
                  <td class="indent">Packaging Recovery Rate <span class="units">(%)</span></td>
                  <template v-for="(design, index) in designs">
                    <td v-if="index == 0">
                      {{ round(design.design_score.wtl_rec_rate_pkg*100) }}
                    </td>
                    <td v-else>
                      <div class="compare-split">
                        <div class="text-right">
                          {{ round(design.design_score.wtl_rec_rate_pkg*100) }}
                        </div>
                        <div class="text-left">  
                          <span v-bind:class="compareChange(
                            design.design_score.wtl_rec_rate_pkg,
                            designs[0].design_score.wtl_rec_rate_pkg,
                            true)">
                            {{ round2((design.design_score.wtl_rec_rate_pkg*100) - (designs[0].design_score.wtl_rec_rate_pkg*100)) }}
                          </span>
                        </div>
                      </div>
                    </td>
                  </template>
                </tr>
                <tr>
                  <td class="compare-head"><i class="fa fa-tree fa-fw text-success" aria-hidden="true"></i> <strong>Optimize Resources</strong></td>
                  <td class="compare-head" v-for="design in designs"></td>
                </tr>
                <tr>
                  <td class="indent">Packaging Weight <span class="units">(g)</span></td>
                  <template v-for="(design, index) in designs">
                    <td v-if="index == 0">
                      {{ round(design.design_score.package_weight_g) }}
                    </td>
                    <td v-else>
                      <div class="compare-split">
                        <div class="text-right">
                          {{ round(design.design_score.package_weight_g) }}
                        </div>
                        <div class="text-left">
                          <span v-bind:class="compareChange(
                            round(design.design_score.package_weight_g), 
                            round(designs[0].design_score.package_weight_g))">
                            {{ round2(calculateChange(
                              round(design.design_score.package_weight_g), 
                              round(designs[0].design_score.package_weight_g))) }}%
                          </span>
                        </div>
                      </div>
                    </td>
                  </template>
                </tr>
                <tr>
                  <td class="indent">Cube Utilization</td>
                  <template v-for="(design, index) in designs">
                    <td v-if="index == 0">
                        {{ round2(design.design_score.master_ratio_cube1_raw) }}
                    </td>
                    <td v-else>
                      <div class="compare-split">
                        <div class="text-right">
                          {{ round2(design.design_score.master_ratio_cube1_raw) }}
                        </div>
                        <div class="text-left">
                          <span v-bind:class="compareChange(
                            design.design_score.master_ratio_cube1_raw, 
                            designs[0].design_score.master_ratio_cube1_raw)">
                            {{ round2(calculateChange(
                              round2(design.design_score.master_ratio_cube1_raw), 
                              round2(designs[0].design_score.master_ratio_cube1_raw))) }}%
                          </span>
                        </div>
                      </div>
                    </td>
                  </template>
                </tr>
                <tr>
                  <td class="indent">Product to Package Ratio</td>
                  <template v-for="(design, index) in designs">
                    <td v-if="index == 0">
                      {{ round2(design.design_score.total_pack_mass_cube2_raw) }}
                    </td>
                    <td v-else>
                      <div class="compare-split">
                        <div class="text-right">
                          {{ round2(design.design_score.total_pack_mass_cube2_raw) }}
                        </div>
                        <div class="text-left">
                          <span v-bind:class="compareChange(design.design_score.total_pack_mass_cube2_raw, designs[0].design_score.total_pack_mass_cube2_raw)">
                            {{ round2(calculateChange(
                              round2(design.design_score.total_pack_mass_cube2_raw), 
                              round2(designs[0].design_score.total_pack_mass_cube2_raw))) }}%
                          </span>
                        </div>
                      </div>
                    </td>
                  </template>
                </tr>
                <tr>
                  <td class="indent">Package to Product Weight Ratio</td>
                  <template v-for="(design, index) in designs">
                    <td v-if="index == 0">
                      <template v-if="null !== design.design_score.package_product_weight_ratio">
                        {{ round2(design.design_score.package_product_weight_ratio) }}
                      </template>
                      <template v-else>
                        ND
                      </template>
                    </td>
                    <td v-else>
                      <div class="compare-split">
                        <div class="text-right">
                          <template v-if="null !== design.design_score.package_product_weight_ratio">
                            {{ round2(design.design_score.package_product_weight_ratio) }}
                          </template>
                          <template v-else>
                            ND
                          </template>
                        </div>
                        <div class="text-left">
                          <span v-if="null !== design.design_score.package_product_weight_ratio && null !== designs[0].design_score.package_product_weight_ratio" v-bind:class="compareChange(
                              round2(design.design_score.package_product_weight_ratio), 
                              round2(designs[0].design_score.package_product_weight_ratio))">
                            {{ round2(calculateChange(
                              round2(design.design_score.package_product_weight_ratio), 
                              round2(designs[0].design_score.package_product_weight_ratio))) }}%
                          </span>
                        </div>
                      </div>
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div> 
        </div>
      </div>

    </section><!-- /compare-industry-metrics -->

  </div>
</template>

<script>

export default {
  name: 'design-compare',

  props: ['designs', 'range'],

  data () {
     return {}
  },

  methods: {
    // percent of change between two numbers
    // @param {number} new value to compare
    // @param {number} baseline to compare to
    // @return {number} percent change
    calculateChange(value, baseline) {
      let difference = value - baseline;
      let change = difference/baseline*100;
      if(isNaN(change)) return 0;
      return change;
    },

    // Determine the keyword based on the change
    // - assumes value lower than baseline is better
    // @param {number} new value to compare
    // @param {number} baseline to compare to
    // @param {bool} reverse so higher values are better
    // @returns {string} keyword [negative|positive|[empty]]
    compareChange(value, baseline, higherIsBetter = false) {
      let change = this.calculateChange(value, baseline);
      if(higherIsBetter === true) {
        return change < 0 ? 'negative' : change > 0 ? 'positive' : 'nochange';
      }
      return change > 0 ? 'negative' : change < 0 ? 'positive' : 'nochange';
    },

    // Simple colorized comparison
    // - assumes higher score is better
    compareScore(value, baseline) {
      let change = value - baseline;
      return value > baseline ? 'positive' : baseline > value ? 'negative' : ''; 
    },

    // @param {number}
    // @returns {string} keyword [above|below|meets]
    scoreKey(score) {
      if(!score) return '';
      return score < this.range.low_norm ? 'below' 
           : score > this.range.high_norm ? 'above' : 'meets';
    },

    // Round to nearest int
    round(input){
      return Math.round(input);
    },

    // Round to two places
    round2(input){
      return Math.round(input * 100) / 100;
    },

    // Round to four places
    round4(input){
      return Math.round(input * 10000) / 10000;
    }
  },

  mounted() {
  }
}
</script>
