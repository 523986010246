'use strict';

var merge = require('merge');

module.exports = function (filter) {

  if (!this.opts.filterable) {
    console.warn("vue-tables-2: Unable to set filter. Filtering is disabled (filterable: false)");
    return;
  };

  if (this.opts.filterByColumn && typeof filter === 'string') {
    console.warn("vue-tables-2: Unable to set filter. Filter value must be an object (`filterByColumn` is set to `true`)");
    return;
  };

  if (!this.opts.filterByColumn && typeof filter !== 'string') {
    console.warn("vue-tables-2: Unable to set filter. Filter value must be a string (`filterByColumn` is set to `false`)");
    return;
  };

  var mergedFilter = this.opts.filterByColumn ? merge(this.query, filter) : filter;

  if (this.vuex) {
    this.commit('SET_FILTER', mergedFilter);
  } else {
    this.query = mergedFilter;
    this.setPage(1, true);
  }

  this.updateState('query', mergedFilter);

  this._setFiltersDOM(filter);

  if (this.source == 'server') {
    this.getData();
  }
};