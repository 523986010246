<docs>
## Notes
This is the top level component for the Design Details screen.

This "page" component is simply a wrapper for the main form
validation and submission state handling.

A 'design-details' element should encapsulate all children.
Import and define top-level components below if needed.
</docs>

<script>
import { HTTP } from './../http-common';
import { Event } from 'vue-tables-2';
import PackagingComponents from './../components/PackagingComponents.vue';

export default {
  name: 'design-details',

  props: {
    projectData: { 
      type: Object,
      required: true
    },
    designData: {
      type: Object,
      required: false,
      default() {
        return {
          name: '',
          details: '',
          design_format_id: null,
          packaging_width_in: null,
          packaging_width_cm: null,
          packaging_depth_in: null,
          packaging_depth_cm: null,
          packaging_height_in: null,
          packaging_height_cm: null,
          master_carton_width_in: null,
          master_carton_width_cm: null,
          master_carton_depth_in: null,
          master_carton_depth_cm: null,
          master_carton_height_in: null,
          master_carton_height_cm: null,
          master_carton_units_per_carton: null,
          free_from_coatings_on_paper: '0',
          free_from_printing_on_plastic: '0',
          all_fasteners_fiber_based: '0',
          opening_automatically_separate_materials: '0',
          free_from_hot_melt_adhesive: '0',
          uses_preferred_inks: '0',
          chosen: null,
          components: []
        }
      }
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
     return {
      project: this.projectData,
      design: this.designData,
      editing: this.editMode,
      next: null,
      loading: false,
      componentsLoading: false,
      validated: false
     }
  },

  computed: {
    showPackagingComponents: function() {
      return (this.editing === true) ? 'showPackagingComponents' : null;
    }
  },

  components: {
    showPackagingComponents: PackagingComponents
  },

  methods: {
    onSubmit() {
      let form = this.$refs.designForm;
      let action = this.editing ? `/api/designs/${this.design.id}` : `/api/projects/${this.project.id}/designs`;

      this.$validator.validateAll().then((result) => {
        if(result) {
          // valid
          this.loading = true;

          HTTP[this.editing ? 'patch' : 'post'](action, this.design)
            .then(response => {
              let uri = (this.next === 'exit') ? '/projects' : `/projects/${this.project.id}/scores/${response.data.id}`;
              window.location.href = uri;
            })
            .catch(e => {
              alert(e);
              this.loading = false;
            });

        } else {
          // invalid
          this.loading = false;
          $(form).find('[aria-invalid="true"]').first().focus();
        }
      });
    },

    save(value) {
      this.next = value;
      this.onSubmit();
    },

    saveAndShowComponents() {
      // validate
      this.$validator.validateAll().then((result) => {
        if(result) {
          this.componentsLoading = true;

          // save design
          HTTP.post(`/api/projects/${this.project.id}/designs`, this.design)
            .then(response => {
              console.log(response);
              this.design.id = response.data.id;
              this.componentsLoading = false;
              // load PackagingComponents component
              this.editing = true;
            })
            .catch(e => {
              alert(e);
              this.componentsLoading = false;
            });
        } else {
          // invalid
          this.componentsLoading = false;
          $('#design-details').find('[aria-invalid="true"]').first().focus();
        }
      });
    },

    componentsUpdated(obj) {
      if(Array.isArray(obj) && obj.length >= 1){
        this.design.components = obj;
      }
    },

    // Both unit types are either set by the parent property or require
    // defaults but we must only set one or the other in our data model.
    localizeUnits() {
      if(this.user.units == 'cm') {
        delete this.design.packaging_width_in;
        delete this.design.packaging_depth_in;
        delete this.design.packaging_height_in;
        delete this.design.master_carton_width_in;
        delete this.design.master_carton_depth_in;
        delete this.design.master_carton_height_in;
      } else {
        delete this.design.packaging_width_cm;
        delete this.design.packaging_depth_cm;
        delete this.design.packaging_height_cm;
        delete this.design.master_carton_width_cm;
        delete this.design.master_carton_depth_cm;
        delete this.design.master_carton_height_cm;
      }
    },

    checkPermissions() {
      if(this.user.id !== this.project.created_by) {
        $('#design-details').find('input, select, textarea, select')
          .prop('readonly', true)
          .prop('disabled', true);
      }
    }
  },

  mounted() {
    Event.$on('packaging.componentsUpdated', obj => this.componentsUpdated(obj));

    this.checkPermissions();

    // bootstrap select
    $('.selectpicker').selectpicker();
  },

  beforeMount(){
    this.$nextTick(function () {
      this.localizeUnits();
    });
  }
}
</script>
