/*
*	http-common.js
*
* This file imports the axios library and exports an axios instance called "HTTP" to be used throughout the application.
*
* Usage: 
*  import { HTTP } from './../http-common'
*  HTTP.get('my-route');
*
* We can use this to conditionally configure all requests by using variables set on the global 'window' object.
* The BASE_URL value will be prepended to all requests, so HTTP.get('my-route') will resolve to url {BASE_URL}/my-route
*/

import axios from 'axios';

const BASE_URL = window.BASE_URL || 'http://spi.localhost:3002/';

export const HTTP = axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  }
});