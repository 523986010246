<docs>
## Notes
This is the top level component for the Design Score screen.

This "page" component is simply a wrapper for displaying and formatting data.
</docs>

<template>
  <div id="design-score">

    <!-- Detailed Report -->
    <div class="detailed-report" id="detailed-report">
      
      <h1>Detailed Report</h1>

      <!-- Source Responsibly -->
      <section class="source">
        <div class="card card-info">
          <div class="card-header">
            <h2 class="card-title"><i class="fa fa-leaf text-success" aria-hidden="true"></i> Source Responsibly 
              <a v-on:click="$root.modal='sourceResponsibly'" data-toggle="modal" href="#modal" class="info"><i class="fa fa-question-circle"></i></a>
              <span class="score">{{ design.design_score.source_responsibly_score }}/100</span>
            </h2>
          </div><!-- /card-header -->
          <div class="card-body">

            <!-- Carbon Footprint -->
            <div class="card card-default">
              <div class="card-body">
                <h4>Carbon Footprint
                  <span class="score">{{ round(design.design_score.climate_change_total_norm) }}/100</span>
                </h4>
                
                <!-- Class names on the legend "keys" control the key colors -->
                <template v-if="cf_component_sum > 0">
                <div class="chart-legend">
                  <ul>
                    <li v-for="(component, index) in design.components">
                      <span v-bind:class="['key', keyClass(index)]"></span>
                      {{ component.name }} <strong>{{ round(component.component_score.cf_component/cf_component_sum*100) }}%</strong>
                    </li>
                  </ul>
                </div><!-- /chart-legend -->

                <div class="progress chart-progress">
                  <div v-for="(component, index) in design.components" v-bind:class="['progress-bar', barClass(index)]" v-bind:style="{width: round(component.component_score.cf_component/cf_component_sum*100)+'%' }" data-toggle="tooltip" :title="component.name+' '+round(component.component_score.cf_component/cf_component_sum*100)+'%'">
                    <!-- Printable -->
                    <svg width="100%" height="20px" viewBox="0 0 20 20" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <rect class="print-bar" x="0" y="0" width="20" height="20" fill="currentColor" />
                    </svg>
                    <span class="sr-only">{{ component.name }} {{ round(component.component_score.cf_component/cf_component_sum*100) }}%</span>
                  </div>
                </div><!-- /progress -->
                </template>
                
              </div><!-- /card-body -->
            </div><!-- /card -->

            <!-- Materials Health -->
            <div class="card card-default">
              <div class="card-body">
                <h4>Materials Health
                  <span class="score">{{ round(design.design_score.human_health_total_norm) }}/100</span>
                </h4>

                <!-- Class names on the legend "keys" control the key colors -->
                <template v-if="mh_component_sum > 0">
                <div class="chart-legend">
                  <ul>
                    <li v-for="(component, index) in design.components">
                      <span v-bind:class="['key', keyClass(index)]"></span>
                      {{ component.name }} <strong>{{ round(component.component_score.mh_component/mh_component_sum*100) }}%</strong>
                    </li>
                  </ul>
                </div><!-- /chart-legend -->
                
                <div class="progress chart-progress">

                  <div v-for="(component, index) in design.components" v-bind:class="['progress-bar', barClass(index)]" v-bind:style="{width: round(component.component_score.mh_component/mh_component_sum*100)+'%' }" data-toggle="tooltip" :title="component.name +' '+round(component.component_score.mh_component/mh_component_sum*100)+'%'">
                    <!-- Printable -->
                    <svg width="100%" height="20px" viewBox="0 0 20 20" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <rect class="print-bar" x="0" y="0" width="20" height="20" fill="currentColor" />
                    </svg>
                    <span class="sr-only">{{ component.name }} {{ round(component.component_score.mh_component/$mh_component_sum*100) }}%</span>
                  </div>

                </div><!-- /progress -->
                </template>
                
              </div><!-- /card-body -->
            </div><!-- /card -->
            
            <!-- Recycled, Certified, Bio -->
            <div class="card card-default">
              <div class="card-body">
                <h4></i> Recycled, Certified, and Bio-Content
                  <span class="score">{{ round(design.design_score.rec_cert_bio_content_pkg*100) }}/100</span>
                </h4>
                
                <!-- Class names on the legend "keys" control the key colors -->
                <div class="chart-legend">
                  <ul>
                    <li>
                      <span class="key key-success">
                        <!-- Printable -->
                        <svg width="100%" height="100%" viewBox="0 0 11 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <rect class="print-bar" x="0" y="0" width="11" height="11" fill="currentColor" />
                        </svg>
                      </span>
                      Recycled, Certified, and Bio-Content <strong>{{ round(design.design_score.rec_cert_bio_content_pkg * 100) }}%</strong>
                    </li>
                    <li>
                      <span class="key key-light">
                        <!-- Printable -->
                        <svg width="100%" height="100%" viewBox="0 0 11 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <rect class="print-bar" x="0" y="0" width="11" height="11" fill="currentColor" />
                        </svg>
                      </span>
                      None <strong>{{ round(100-design.design_score.rec_cert_bio_content_pkg * 100) }}%</strong>
                    </li>
                  </ul>
                </div><!-- /chart-legend -->
                
                <div class="progress">
                  <div class="progress-bar progress-bar-success" v-bind:style="{width: round(design.design_score.rec_cert_bio_content_pkg*100)+'%'}" data-toggle="tooltip" :title="'Recycled, Certified, and Bio-Content '+round(design.design_score.rec_cert_bio_content_pkg*100)+'%'">
                    <!-- Printable -->
                    <svg width="100%" height="100%" viewBox="0 0 20 20" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <rect class="print-bar" x="0" y="0" width="20" height="20" fill="currentColor" />
                    </svg>
                    <span class="sr-only">Recycled, Certified, and Bio-Content {{ round(design.design_score.rec_cert_bio_content_pkg*100) }}%</span>
                  </div>
                  <div class="progress-bar progress-bar-light" v-bind:style="{width: round(100-design.design_score.rec_cert_bio_content_pkg*100)+'%'}" data-toggle="tooltip" :title="'None '+round(100-design.design_score.rec_cert_bio_content_pkg*100)+'%'">
                    <!-- Printable -->
                    <svg width="100%" height="20px" viewBox="0 0 20 20" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <rect class="print-bar" x="0" y="0" width="20" height="20" fill="currentColor" />
                    </svg>
                    <span class="sr-only">None {{ round(100-design.design_score.rec_cert_bio_content_pkg*100) }}%</span>
                  </div>
                </div><!-- /progress -->
                
              </div><!-- /card-body -->
            </div><!-- /card -->

          </div><!-- /card-body -->
        </div><!-- /card -->
      </section><!-- /source -->

      <!-- Design for Recycling -->
      <section class="recycling">
        <div class="card card-info">
          <div class="card-header">
            <h2 class="card-title"><i class="fa fa-recycle text-primary" aria-hidden="true"></i> Design for Recycling 
              <a v-on:click="$root.modal='designForRecycling'" data-toggle="modal" href="#modal" class="info"><i class="fa fa-question-circle"></i></a>
              <span class="score">{{ design.design_score.design_for_recycling_score }}/100</span>
            </h2>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <th>Recyclability:</th>
                  <td>{{ round(design.design_score.wtl_rec_ratio_pkg) }}/100</td>
                </tr>
                <tr>
                  <th>Unique Materials:</th>
                  <td>{{ round(design.design_score.dfr_materials) }}/100</td>
                </tr>
                <tr>
                  <th>Design for Recycling - Questions:</th>
                  <td>{{ round(design.design_score.dfr_questions_pkg) }}/100</td>
                </tr>
              </tbody>
            </table>
          </div><!-- /card-body -->
        </div><!-- /card -->
      </section><!-- /recycling -->

      <!-- Optimize Resources -->
      <section class="resources">
        <div class="card card-info">
          <div class="card-header">
            <h2 class="card-title"><i class="fa fa-tree text-success" aria-hidden="true"></i> Optimize Resources 
              <a v-on:click="$root.modal='optimizeResources'" data-toggle="modal" href="#modal" class="info"><i class="fa fa-question-circle"></i></a>
              <span class="score">{{ design.design_score.optimize_resources_score }}/100</span>
            </h2>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <th>Total Material Mass:</th>
                  <td>{{ round(design.design_score.cube1_norm) }}/100</td>
                </tr>
                <tr>
                  <th>Cube Utilization:</th>
                  <td>{{ round(design.design_score.cube2_norm) }}/100</td>
                </tr>
              </tbody>
            </table>
          </div><!-- /card-body -->
        </div><!-- /card -->
      </section><!-- /resources -->

      <!-- Industry Metrics -->
      <section class="industry-metrics">
        <div class="card card-info">
          <div class="card-header">
            <h2 class="card-title"><i class="fa fa-calculator text-primary" aria-hidden="true"></i> Industry Metrics 
              <a v-on:click="$root.modal='industryMetrics'" data-toggle="modal" href="#modal" class="info"><i class="fa fa-question-circle"></i></a>
            </h2>
          </div>
          <div class="card-body">
            <div class="card card-default">
              <div class="card-body">
                <h4><i class="fa fa-leaf text-success" aria-hidden="true"></i> Source Responsibly</h4>
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <th>Carbon Footprint <span class="units">(g CO<sub>2</sub>eq)</span>:</th>
                      <td>{{ round2(design.design_score.cf_raw) }}</td>
                    </tr>
                    <tr>
                      <th>Materials Health <span class="units">(DALY * 10<sup>8</sup>)</span>:</th>
                      <td>{{ round2(design.design_score.mh_raw) }}</td>
                    </tr>
                    <tr>
                      <th>Recycled, Certified, and Bio-Content:</th>
                      <td>{{ round2(design.design_score.rec_cert_bio_content_pkg * 100) }}%</td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- /card-body -->
            </div><!-- /card -->
            <div class="card card-default">
              <div class="card-body">
                <h4><i class="fa fa-recycle text-primary" aria-hidden="true"></i> Design for Recycling</h4>
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <th>Packaging Recovery Rate:</th>
                      <td>{{ round2(design.design_score.wtl_rec_rate_pkg * 100) }}%</td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- /card-body -->
            </div><!-- /card -->
            <div class="card card-default">
              <div class="card-body">
                <h4><i class="fa fa-tree text-success" aria-hidden="true"></i> Optimize Resources</h4>
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <th>Primary Packaging Weight:</th>
                      <td>{{ round4(design.design_score.package_weight_g) }} g</td>
                    </tr>
                    <tr>
                      <th>Cube Utilization:</th>
                      <td>{{ round2(design.design_score.master_ratio_cube1_raw) }}</td>
                    </tr>
                    <tr>
                      <th>Product to Package Ratio:</th>
                      <td v-if="design.design_score.master_carton_volume_cm3 > 0">
                        {{ round2(design.design_score.total_pack_mass_cube2_raw) }}
                      </td>
                      <td v-else>
                        ND
                      </td>
                    </tr>
                    <tr>
                      <th>Package to Product Weight Ratio:</th>
                      <td v-if="null !== design.design_score.package_product_weight_ratio">
                        {{ round2(design.design_score.package_product_weight_ratio) }}
                      </td>
                      <td v-else>
                        ND
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- /card-body -->
            </div><!-- /card -->
          </div><!-- /card-body -->
        </div><!-- /card -->
      </section><!-- /industry-metrics -->

      <!-- Design Details -->
      <section class="design-details">
        <div class="card card-info">
          <div class="card-header">
            <h2 class="card-title">Design Details</h2>
          </div>
          <div class="card-body">
            <div class="card card-default">
              <div class="card-body">
                <h4>Estimated Packaging Dimensions</h4>
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <th>Height:</th>
                      <td v-if="user.units === 'in'">{{ round2(design.packaging_height_in) }} in</td>
                      <td v-if="user.units === 'cm'">{{ round2(design.packaging_height_cm) }} cm</td>
                    </tr>
                    <tr>
                      <th>Width:</th>
                      <td v-if="user.units === 'in'">{{ round2(design.packaging_width_in) }} in</td>
                      <td v-if="user.units === 'cm'">{{ round2(design.packaging_width_cm) }} cm</td>
                    </tr>
                    <tr>
                      <th>Depth:</th>
                      <td v-if="user.units === 'in'">{{ round2(design.packaging_depth_in) }} in</td>
                      <td v-if="user.units === 'cm'">{{ round2(design.packaging_depth_cm) }} cm</td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- /card-body -->
            </div><!-- /card -->
            <div class="card card-default">
              <div class="card-body">
                <h4>Master Carton Dimensions</h4>
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <th>Height:</th>
                      <td v-if="user.units === 'in'">
                        <template v-if="design.master_carton_height_in">
                          {{ round2(design.master_carton_height_in) }} in
                        </template>
                      </td>
                      <td v-if="user.units === 'cm'">
                        <template v-if="design.master_carton_height_cm">
                          {{ round2(design.master_carton_height_cm) }} cm
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <th>Width:</th>
                      <td v-if="user.units === 'in'">
                        <template v-if="design.master_carton_width_in">
                          {{ round2(design.master_carton_width_in) }} in
                        </template>
                      </td>
                      <td v-if="user.units === 'cm'">
                        <template v-if="design.master_carton_width_cm">
                          {{ round2(design.master_carton_width_cm) }} cm
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <th>Depth:</th>
                      <td v-if="user.units === 'in'">
                        <template v-if="design.master_carton_depth_in">
                          {{ round2(design.master_carton_depth_in) }} in
                        </template>
                      </td>
                      <td v-if="user.units === 'cm'">
                        <template v-if="design.master_carton_depth_cm">
                          {{ round2(design.master_carton_depth_cm) }} cm
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- /card-body -->
            </div><!-- /card -->
            <div class="card card-default">
              <div class="card-body">
                <h4>Packages Per Master Carton</h4>
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <th>Units:</th>
                      <td>{{ design.master_carton_units_per_carton }}</td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- /card-body -->
            </div><!-- /card -->
            <div class="card card-default">
              <div class="card-body">
                <h4>Size of Item(s) in Package</h4>
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <th>Size:</th>
                      <td>{{ project.product_size.name }}</td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- /card-body -->
            </div><!-- /card -->
          </div><!-- /card-body -->
        </div><!-- /card -->
      </section><!-- /design-details -->

      <!-- Components -->
      <section class="components">
        <div class="card card-info">
          <div class="card-header">
            <h2 class="card-title">Components</h2>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Component Name</th>
                  <th>Material Type</th>
                  <th>Mass (g)</th>
                  <th>Data Source</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(component, index) in design.components">
                  <td>{{ component.name}}</td>
                  <td>{{ component.material.name}}</td>
                  <td>{{ round6(component.mass_g) }}</td>
                  <td>{{ component.calculated ? 'Calculated' : 'Entered' }}</td>
                </tr>
              </tbody>
            </table>
          </div><!-- /card-body -->
        </div><!-- /card -->
      </section><!-- /components -->

      <!-- Questions -->
      <section class="questions">
        <div class="card card-info">
          <div class="card-header">
            <h2 class="card-title">Questions</h2>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <th>Free from any coatings on paper that may inhibit recycling?</th>
                  <td>{{ answers[design.free_from_coatings_on_paper] }}</td>
                </tr>
                <tr>
                  <th>Free from printing on plastic?</th>
                  <td>{{ answers[design.free_from_printing_on_plastic] }}</td>
                </tr>
                <tr>
                  <th>Are all fasteners fiber based?</th>
                  <td>{{ answers[design.all_fasteners_fiber_based] }}</td>
                </tr>
                <tr>
                  <th>Does the act of opening the package automatically and completely separate different materials from each other?</th>
                  <td>{{ answers[design.opening_automatically_separate_materials] }}</td>
                </tr>
                <tr>
                  <th>Free from Hot Melt Adhesive?</th>
                  <td>{{ answers[design.free_from_hot_melt_adhesive] }}</td>
                </tr>
                <tr>
                  <th>Uses preferred inks such as vegetable, soy?</th>
                  <td>{{ answers[design.uses_preferred_inks] }}</td>
                </tr>
              </tbody>
            </table>
          </div><!-- /card-body -->
        </div><!-- /card -->
      </section><!-- /questions -->

    </div><!-- /detailed-report -->

  </div>
</template>

<script>

export default {
  name: 'design-score',

  props: ['project', 'design', 'range'],

  data () {
    return {
      answers: {
        '1': 'Yes',
        '0': 'No',
        '2': 'Don’t Know'
      }
    }
  },

  computed: {
    cf_component_sum() {
      let componentSum = 0;
      this.design.components.forEach((component) => {
        componentSum = componentSum + Number(component.component_score.cf_component);
      });

      return componentSum;
    },
    mh_component_sum() {
      let componentSum = 0;
      this.design.components.forEach((component) => {
        componentSum = componentSum + Number(component.component_score.mh_component);
      });

      return componentSum;
    }
  },

  methods: {
    // @param {number}
    // @returns {string} keyword [above|below|meets]
    scoreKey(score) {
      if(!score) return '';
      return score < this.range.low_norm ? 'below' 
           : score > this.range.high_norm ? 'above' : 'meets';
    },

    // Round to nearest int
    round(input){
      return Math.round(input);
    },

    // Round to two places
    round2(input){
      return Math.round(input * 100) / 100;
    },

    // Round to four places
    round4(input){
      return Math.round(input * 10000) / 10000;
    },

    // Round to six places
    round6(input){
      return Math.round(input * 1000000) / 1000000;
    },

    keyClass(index){
      let i = index++;
      return `key-${i%10}`;
    },

    barClass(index){
      let i = index++;
      return `key-${i%10}`;
    }
  },

  mounted() {
    this.$nextTick(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }
}
</script>
