<docs>
## Notes
Displays a paper mass calclator.
Emits "updateMass" event with mass value.
Converts units based on user prefs.
Repopulates inputs with stored data if prop is passed.

Formula: Calculater v1.3.xlxs

  (length * width) * strength * 0.0001
</docs>

<template>
  <div class="card card-info mt-3">
    <div class="card-header">
      <h3 class="card-title">Paper Calculator</h3>
    </div>
    <div class="card-body">
      <p class="alert alert-info">Average values for paper thickness are 200 – 500 gsm</p>
      
      <div class="form-group">
        <label>Length</label>
        <div>
          <input type="number" class="form-control input-narrow" name="length" v-model="length">
          <span class="text-muted" v-if="user.units == 'in'">inches</span>
          <span class="text-muted" v-if="user.units == 'cm'">cm</span>
        </div>
      </div>
      <div class="form-group">
        <label>Width</label>
        <div>
          <input type="number" class="form-control input-narrow" name="width" v-model="width">
          <span class="text-muted" v-if="user.units == 'in'">inches</span>
          <span class="text-muted" v-if="user.units == 'cm'">cm</span>
        </div>
      </div>
      <div class="form-group">
        <label>Strength</label><br>
        <input type="number" class="form-control input-narrow" name="strength" v-model="strength">
        <span class="text-muted">gsm</span>
      </div>
      <div class="form-group">
        <label>Mass</label><br>
        <input type="number" class="form-control input-narrow" name="mass" v-model="mass" readonly>
        <span class="text-muted">grams</span>
      </div>

      <button type="button" class="btn btn-info btn-sm" @click.prevent="computeMass" v-bind:disabled="!isValid">
        Calculate mass
      </button>
      <button type="button" class="btn btn-info btn-sm" @click.prevent="updateMass" v-bind:disabled="!isValid">
        Use this mass
      </button>
    </div>
  </div>
</template> 

<script>

export default {
  name: 'paper-mass-calculator',

  props: ['calculatorData'],

  data () {
     return {
      length: 0,
      width: 0,
      strength: 0,
      mass: null,
      units: this.user.units
     }
  },

  computed: {
    isValid: function() {
      return (this.length > 0 && this.width > 0 && this.strength > 0);
    }
  },

  methods: {
    computeMass(){
      let length = this.length;
      let width  = this.width;

      if(this.user.units === 'in') {
        // convert in to cm for calculation
        length = this.round2(this.length * 2.54);
        width  = this.round2(this.width * 2.54);
      }

      this.mass = this.round2((length*width)*this.strength*0.0001);
    },

    updateMass(){
      this.$emit('useCalculatedMass', this.getCalculatorData());
    },

    getCalculatorData(){
      return {
        length: this.length,
        width: this.width,
        strength: this.strength,
        mass: this.mass,
        units: this.units
      }
    },

    setCalculatorData(){
      let obj = this.calculatorData;

      if(obj === null || typeof obj !== 'object') {
        return;
      }

      // restore calculated values, then check for conversions
      this.length = obj.length;
      this.width = obj.width;
      this.strength = obj.strength;
      this.mass = obj.mass;

      if(obj.units == 'in' && this.user.units === 'cm') {
        // convert in to cm
        this.length = this.round2(obj.length * 2.54);
        this.width  = this.round2(obj.width * 2.54);
      }

      if(obj.units == 'cm' && this.user.units === 'in') {
        // convert cm to in
        this.length = this.round2(obj.length/ 2.54);
        this.width  = this.round2(obj.width / 2.54);
      }
    },

    round2(input){
      return Math.round(input * 100) / 100;
    }
  },

  beforeMount(){
    this.setCalculatorData();
  }
}
</script>
