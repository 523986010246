let user = window.Spi.user;

module.exports = {
	getUserProp(prop, defaultValue = null) {
		if(user !== null && user.hasOwnProperty(prop)) {
			return user[prop];
		}
		return defaultValue;
	},
	getUserPref(prop, defaultValue = null) {
		if (user !== null && user.preferences !== null && Object.keys(user.preferences).length) {
			if(user.preferences.hasOwnProperty(prop)) {
				return user.preferences[prop];
			}
		}
		return defaultValue;
	}
};