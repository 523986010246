/* 
* main.js
* Main entry point for the Vue.js application. We kick off a vue instance and load the top-level app component.
* We import this file into browseify to bundle everything into one compiled lump of code
*/

import 'babel-polyfill';
import Vue from 'vue';
import Spi from './spi-common';
import App from './App.vue';
import ProjectDetails from './pages/ProjectDetails.vue';
import DesignDetails from './pages/DesignDetails.vue';
import DesignReview from './pages/DesignReview.vue';
import DesignCompare from './pages/DesignCompare.vue';
import DesignScore from './pages/DesignScore.vue';
import UserPreferences from './pages/UserPreferences.vue';
import {ServerTable, ClientTable, Event} from 'vue-tables-2';
import VeeValidate from 'vee-validate';

Vue.use(ClientTable, {}, false, 'bootstrap4', 'default');
Vue.use(VeeValidate);

Vue.prototype.signedIn = window.Spi.signedIn;
Vue.prototype.user = {
  id: Spi.getUserProp('id'),
  name: Spi.getUserProp('name'),
  email: Spi.getUserProp('email'),
  units: Spi.getUserPref('units', 'in')
};

new Vue({ // eslint-disable-line no-new
  el: '#root',
  components: { 
  	App, 
  	ProjectDetails,
  	DesignDetails,
  	DesignReview,
  	DesignCompare,
    DesignScore,
  	UserPreferences
  },
  data(){
    return {
      modal: String, // let's move this to a component
      modalData: Object
    }
  }
});