"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function () {
  return {
    computed: {
      Columns: function Columns() {
        return this.columns;
      }
    }
  };
};