<docs>
## Notes
Displays a simple form under a selected row within the ProjectTable component's v-client-table.
ProjectsTableForm can emit 'updateProject', 'copyProject', 'deleteProject' events.
ProjectsTableForm "save" button will hide/show only if properties have changes and are present.
</docs>

<template>
  <div class="table-form">
    <div v-if="rowCanEdit" class="pull-left form-inline">
      <div class="form-group">
        <input v-model="name" class="form-control" placeholder="Project name">
      </div>
      
      <div class="form-group">
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input class="form-check-input" type="radio" value="1" v-model="active"> Active
          </label>
        </div>
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input class="form-check-input" type="radio" value="0" v-model="active"> Inactive
          </label>
        </div>
      </div>

      <button type="button" class="btn btn-primary" v-show="rowCanSave" :disabled="!rowCanSave" @click="updateRow"><i class="fa fa-save fa-fw"></i> Save</button>

    </div>
    <div class="pull-right">
      <button type="button" class="btn btn-light" @click="copyRow"><i class="fa fa-files-o fa-fw"></i> Copy</button>
      <button type="button" v-if="rowCanEdit" class="btn btn-danger" @click="deleteRow"><i class="fa fa-trash fa-fw"></i> Delete</button>
    </div>
  </div>
</template>

<script>
import { Event } from 'vue-tables-2';

export default {
  name: 'projects-table-form',
  
  props: [
    'data', 
    'index'
  ],
  
  data () {
    return {
      name: this.data.name,
      active: this.data.active
    }
  },

  computed: {
    rowCanSave() {
     return this.name != '' && (this.name != this.data.name || this.active != this.data.active)
    },

    rowCanEdit() {
      return this.data.user_is_owner;
    }
  },

  methods: {
    updateRow() {
      Event.$emit('updateProject', {id: this.data.id, name: this.name, active: this.active});
    },

    copyRow() {
       Event.$emit('copyProject', this.data.id);
    },

    deleteRow() {
      if(window.confirm(`Are you sure that you want to delete ${this.data.name}?`)){
        Event.$emit('deleteProject', this.data.id);
      }
    }
  }
}
</script>

<style>
  .table-form .form-group {
    margin-right: 10px;
  }
</style>