<docs>
## Notes
This is the top level component for the Project Details screen.

This "page" component is simply a wrapper for the main form
validation and submission state handling.

A 'project-details' element should encapsulate all children.
Import and define top-level components below if needed.
</docs>

<script>
import { HTTP } from './../http-common';
import * as constants from './../constants-common.js';

export default {
  name: 'project-details',

  props: {
    projectData: {
      type: Object,
      required: false,
      default() {
        return {
          active: 1,
          name: '',
          created_by: null,
          project_category_id: null,
          project_subcategory_id: null,
          year: '',     
          season: '',
          product_size_id: null,
          project_msrp_range_id: null,
          project_quantity_id: null,
          product_width_in: null,
          product_width_cm: null,
          product_depth_in: null,
          product_depth_cm: null,
          product_height_in: null,
          product_height_cm: null,
          product_weight: null,
          chosen_design_id: null
        }
      }
    }, 
    editMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
     return {
      project: this.projectData,
      editing: this.editMode,
      next: null,
      loading: false,
      validated: false,
      designReturn: false,
      dimensionsWarning: false,
      // dropdown options
      options: {
        categories: constants.PROJECT_OPTIONS
        // @todo - add msrp ranges, etc. ?
      }
     }
  },

  computed: {
    categoryOptions() {
      let keys = ['toy', 'toy_acc', 'multi'];

      if(!this.project.project_quantity_id) {
        return null;
      }

      return this.options.categories[keys[this.project.project_quantity_id-1]];
    },
    subcategoryOptions() {
      return this.options.categories.playsets;
    }
  },

  methods: {
    onSubmit() {
      let form = this.$refs.projectForm;
      let action = this.editing ? `/api/projects/${this.project.id}` : '/api/projects';

      this.$validator.validateAll().then((result) => {
        if(result) {
          // valid
          this.loading = true;

          HTTP[this.editing ? 'patch' : 'post'](action, this.project)
            .then(response => {
              let uri = (this.next === 'exit') ? '/projects' : (this.editing && this.designReturn) ? 
                `/projects/${response.data.id}/designs/${this.designReturn}` : 
                `/projects/${response.data.id}/designs/new`;
              window.location.href = uri;
            })
            .catch(e => {
              alert(e);
              this.loading = false;
            });

        } else {
          // invalid
          this.loading = false;
          $(form).find('[aria-invalid="true"]').first().focus();
        }
      });
    },

    save(value, designId = false) {
      this.next = value;
      this.designReturn = designId;
      this.onSubmit();
    },

    refreshSelects(){
      // bootstrap select - yes this is gross
      this.$nextTick(function () {
        $('.selectpicker').selectpicker('refresh');
      });
    },

    // Both unit types are either set by the parent property or require
    // defaults but we must only set one or the other in our data model.
    localizeUnits() {
      if(this.user.units === 'cm') {
        delete this.project.product_width_in;
        delete this.project.product_depth_in;
        delete this.project.product_height_in;
      } else {
        delete this.project.product_width_cm;
        delete this.project.product_depth_cm;
        delete this.project.product_height_cm;
      }
    },

    clearDimensions() {  
      this.dimensionsWarning = false;
         
      if(this.user.units === 'cm' && 
        (this.project.product_width_cm || this.project.product_depth_cm || this.project.product_height_cm)
      ) {
        this.project.product_width_cm = null;
        this.project.product_depth_cm = null;
        this.project.product_height_cm = null; 
        this.dimensionsWarning = true;
      } else if (this.project.product_width_in || this.project.product_depth_in || this.project.product_height_in) {
        this.project.product_width_in = null;
        this.project.product_depth_in = null;
        this.project.product_height_in = null;
        this.dimensionsWarning = true;
      }
    },

    estimateDimensions() {
      this.dimensionsWarning = false;

      if(this.user.units === 'cm') {
        if(this.project.product_width_cm && this.project.product_depth_cm && this.project.product_height_cm) {
          const calculatedDims = Math.round(this.project.product_width_cm*this.project.product_depth_cm*this.project.product_height_cm);

          if(calculatedDims > 0 && calculatedDims <= 250) {
            this.project.product_size_id = 1;
          } else if(calculatedDims >= 251 && calculatedDims <= 2500) { 
            this.project.product_size_id = 2;
          } else if(calculatedDims >= 2501 && calculatedDims <= 8000) { 
            this.project.product_size_id = 3;
          } else if(calculatedDims >= 8001 && calculatedDims <= 45000) { 
            this.project.product_size_id = 4;
          } else if(calculatedDims >= 45000) { 
            this.project.product_size_id = 5;
          }
        }
      } else {
          const calculatedDims = Math.round(this.project.product_width_in*this.project.product_depth_in*this.project.product_height_in);

          if(calculatedDims > 0 && calculatedDims <= 15) {
            this.project.product_size_id = 1;
          } else if(calculatedDims >= 16 && calculatedDims <= 150) { 
            this.project.product_size_id = 2;
          } else if(calculatedDims >= 151 && calculatedDims <= 490) { 
            this.project.product_size_id = 3;
          } else if(calculatedDims >= 491 && calculatedDims <= 2750) { 
            this.project.product_size_id = 4;
          } else if(calculatedDims >= 2751) { 
            this.project.product_size_id = 5;
          }
      }
    },

    checkPermissions() {
      if(this.editMode && this.user.id !== this.project.created_by) {
        let form = $('#project-details');
        form.find('input, textarea, select').prop('readonly', true);
        form.find('select, input[type=checkbox], input[type=radio]').prop('disabled', true);
      }
    }
  },

  mounted() {
    this.checkPermissions();

    // bootstrap select
    $('.selectpicker').selectpicker();
  },

  beforeMount(){
    this.$nextTick(function () {
      this.localizeUnits();
    });
  }
}
</script>
