<template>
  <form id="user-preferences" class="form row">      
    <div class="col-lg-4">

      <div v-if="success" class="alert alert-success" role="alert">
        <strong>Success!</strong> Your preferences have been updated.
      </div>

      <div class="form-group">
        <label>Default units of measure</label>
        <div class="form-check ml-2">
          <label class="form-check-label">
            <input class="form-check-input" type="radio" name="units" value="in" v-model="preferences.units">
            Inches
          </label>
        </div>
        <div class="form-check ml-2">
          <label class="form-check-label">
            <input class="form-check-input" type="radio" name="units" value="cm" v-model="preferences.units">
            Centimeters
          </label>
        </div>
      </div><!-- /.form-group -->

    </div><!-- /.col -->
    <div class="col-lg-12">

      <nav class="project-pager mt-3 clearfix">
        <button type="submit" class="btn btn-primary" @click.prevent="handleUpdate">
          <span v-if="loading">Loading...</span>
          <span v-else>Save Preferences</span>
        </button>
        <a href="/projects" class="btn btn-link">Cancel</a>
      </nav>

    </div><!-- /.col -->
  </form>
</template>

<script>
import { HTTP } from './../http-common';

export default {
  name: 'user-preferences',

  data() {
    return {
      preferences: {
        units: this.user.units
      },
      loading: false,
      success: false
    }
  },

  methods: {
    handleUpdate() {
      this.loading = true;
      this.success = false;

      HTTP.post('/api/user/preferences', this.preferences)
        .then(response => {
          this.loading = false;
          this.success = true;
        })
        .catch(e => {
          alert(e);
          this.loading = false;
        });
    }
  }
}
</script>