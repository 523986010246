<docs>
## Notes
Displays a list of projects available to the user.
ProjectTable.vue implements the ProjectsTableForm component. This is shown when editing the component. 
We display table data within a v-client-table component.
ProjectTable can remove a project.
ProjectTable can copy a project.
ProjectTable can update certain attributes of a project.
</docs>

<template>
  <div id="projects-table">  
    <v-client-table :data="tableData" :columns="columns" :options="options" name="projects-table" ref="projectsTable">

      <template slot="beforeFilter">
        <ul class="page-actions VueTables__actions">
          <li><a href="/projects/new" class="btn btn-primary"><i class="fa fa-plus" aria-hidden="true"></i> Create New Project</a></li>
        </ul>
      </template>

      <template slot="afterFilter">
        <div class="form-group VueTables__search-filter">
          <label class="filter-label">Showing:</label>
          <select class="selectpicker" v-on:change="filterSelect($event)">
            <option value="my-active" selected>My Active Projects</option>
            <option value="my-inactive">My Inactive Projects</option>
            <option data-divider="true"></option>
            <option value="all-active" >All Active Projects</option>
            <option value="all-inactive">All Inactive Projects</option>
            <option value="all">All Projects</option>
          </select>
        </div>
      </template>
      
      <template slot="name" slot-scope="props">
        <a :href="`/projects/${props.row.id}/designs`">{{props.row.name}}</a>
      </template>
      
      <template slot="status" slot-scope="props">
        <a href="#status-modal" v-on:click.prevent="statusModal(props.row.status.toLowerCase())">
          <span class="badge badge-secondary" v-bind:class="{ 'badge-active' : props.row.status === 'Active' }" >{{props.row.status}}</span>
        </a>
      </template>

    </v-client-table>
  </div>
</template>

<script>
import { HTTP } from './../http-common';
import { Event } from 'vue-tables-2';
import ProjectsTableForm from './ProjectsTableForm.vue'

export default {
  name: 'projects-table',

  data () {
    return {
      columns: ['name', 'designs_count', 'season', 'updated_at', 'created_by_email', 'status'],

      tableData: [],

      options: {
        headings: {
          name: 'Project Name',
          designs_count: 'Designs',
          updated_at: 'Last Modified',
          created_by_email: 'Created By',
          status: 'Project Status'
        },
        skin: 'table table-striped',
        sortable: ['name', 'designs_count', 'season', 'updated_at', 'created_by_email', 'status'],
        sortIcon: {base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort'},
        pagination: {edge: false, align: 'right'},

        texts:{
          filterPlaceholder: 'Search...'
        },
        childRow: ProjectsTableForm,
        childRowTogglerFirst: false,
        customFilters: [
          {
            name: 'owner',
            callback: function (row, query) {
                if(query === true) {
                  return row.user_is_owner === true;
                } else {
                  return true;
                }
            }
          },
          {
            name: 'inactive',
            callback: function (row, query) {
                if(query === 'all') {
                  return true;
                } else if(query === 'inactive') {
                  return row.status === 'Inactive';
                } else {
                  return row.status !== 'Inactive';
                }
            }
          },
        ],
        initFilters: {
          owner: true,
          inactive: 'active'
        },
        orderBy: {
          column: 'updated_at',
          ascending: false
        },
        multiSorting: {
          'updated_at' : [
            {
              column: 'id',
              matchDir: true
            }
          ]
        }
      }
    }
  },

  methods: {
    // Fetch our projects
    fetchProjects(){
      HTTP.get('/api/projects')
        .then(response => {
          if(Array.isArray(response.data)){
            this.tableData = response.data;
          } else {
            throw new Error('ProjectTable Component - fetchProjects - response.data is not an array.');
          }
        })
        .catch(e => {
          alert(e);
        });
    },

    // Update the project by ID
    //  @param {obj} obj - An object with updated properties
    //  @param {string} obj.id - A project id.
    //  @param {string} obj.status - The status of the project.
    //  @param {string} obj.name - The name of a project.
    //  @param {bool} obj.active - The active state of the project.
    updateProject(obj){
      if(obj.constructor === Object && Object.keys(obj).length !== 0)
      {
        HTTP.patch(`/api/projects/${obj.id}`, obj)
        .then(response => {
          const index = this.tableData.findIndex(function (row) { return row.id === obj.id; });
          this.tableData[index].name = response.data.name;
          this.tableData[index].active = response.data.active;
          this.tableData[index].status = response.data.status;
          this.closeAllRows();
        })
        .catch(e => {
          alert(e);
        });
      }
    },

    // Create a new project from a project by ID. Response should return project object.
    // @param {string} id - A project id.
    copyProject(id){
      if(id)
      {
        HTTP.post(`/api/projects/${id}/copy`)
        .then(response => {
          const clone = response.data;
          if(clone.constructor === Object && Object.keys(clone).length !== 0){
            this.tableData.push(clone);
            this.$refs.projectsTable.setOrder('updated_at', false); // re-sort by date descending
            this.$refs.projectsTable.setPage(1);
            this.closeAllRows();
          } else {
            throw new Error('ProjectTable Component - copyProject- response.data is empty or not an object.');
          }
        })
        .catch(e => {
          alert(e);
        });
      }
    },
    
    // Delete the project by ID
    //  @param {string} id - A project id.
    deleteProject(id){
      if(id)
      {
        const index = this.tableData.findIndex(function (obj) { return obj.id === id; });

        if(index > -1) {
          HTTP.delete(`/api/projects/${id}`)
          .then(response => {
            this.tableData.splice(index, 1);
          })
          .catch(e => {
            alert(e);
          });
        }
      }
    },

    closeAllRows() {
      this.$refs.projectsTable.openChildRows.map((val, index) => {
        this.$refs.projectsTable.toggleChildRow(val);
      });
    },

    filterSelect(event){
      this.closeAllRows();
      switch(event.srcElement.value) {
        case 'my-active':
          this.filterMyProjects(true);
          this.filterInactiveProjects('active');
          break;
        case 'my-inactive':
          this.filterMyProjects(true);
          this.filterInactiveProjects('inactive');
          break;
        case 'all-active':
          this.filterMyProjects(false);
          this.filterInactiveProjects('active');
          break;
        case 'all-inactive':
          this.filterMyProjects(false);
          this.filterInactiveProjects('inactive');
          break;
        default:
          this.filterMyProjects(false);
          this.filterInactiveProjects('all');
          break;
      }
    },

    filterMyProjects(query){
      Event.$emit('vue-tables.projects-table.filter::owner', query);
    },

    filterInactiveProjects(query){
      Event.$emit('vue-tables.projects-table.filter::inactive', query);
    },

    statusModal(status) {
      this.$root.modal = status;
      $('#status-modal').modal();
    }
  },

  beforeMount(){
    this.fetchProjects();
  },

  mounted() {
    Event.$on('updateProject', obj => this.updateProject(obj));
    Event.$on('copyProject', id => this.copyProject(id));
    Event.$on('deleteProject', id => this.deleteProject(id));

    // bootstrap select
    $('.selectpicker').selectpicker();
  },

  beforeDestroy() {
    Event.$off(['updateProject', 'copyProject', 'deleteProject']);
  }
}
</script>



<style>
.VueTables__search {
  display: block;
  float: none !important;
  margin-bottom: 16px;
  width: 100%;
}
.VueTables__search::after {
  display: block;
  clear: both;
  content: "";
}

.VueTables__search-field {
  position: relative;
  float: none;
  margin: 0 0 10px;
}

.VueTables__search-field::before {
  font: normal normal normal 18px/1 FontAwesome;
  content: "\f002";
  color: #777;
  display: inline-block;
  position: absolute;
  top: 12px;
  left: 12px;
}

.VueTables__search-field input {
  padding-left: 38px;
}
.VueTables__search-field label,
.VueTables__limit {
  display: none;
}

.VueTables__search-filter {
  margin: 0 0 10px;
}

.VueTables__search .filter-label {
  display: block;
  width: 100%;
  margin: 0 0 4px;
}

.VueTables__actions {
  margin-bottom: 20px;
}

@media (min-width: 800px) {
  .VueTables__search-field {
    display: inline-block;
    margin-left: 15px;
    float: right !important;
  }
  .VueTables__search-filter {
    display: inline-block;
    float: right;
    clear: none;
  }
  .VueTables__search .filter-label {
    display: inline-block;
    width: auto;
    margin: 0 4px 0 0;
  }
  .VueTables__actions {
    float: left;
    margin-bottom: 0;
  }
}

.VueTables__table th {
  border: 1px solid #d8d8d8;
  font-size: 99%;
}

.VueTables__sortable {
  position: relative;
  padding-right: 25px !important;
}
.VueTables__sortable:hover {
  cursor: pointer;
  background-color: #f9f9f9;
}
.VueTables__sortable 
.VueTables__sort-icon {
  opacity: 0.3;
  position: absolute;
  bottom: 16px;
  right: 8px;
}

.VueTables__child-row-toggler {
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: 3px auto 0;
  text-align: center;
}

.VueTables__child-row-toggler--closed::before {
  font: normal normal normal 18px/1 FontAwesome;
  content: '\f013';
  color: #777;
}

.VueTables__child-row-toggler--open::before {
  font: normal normal normal 18px/1 FontAwesome;
  content: '\f00d';
  color: #777;
}

.VueTables .btn-sm {
  min-width: 6em;
}

.VuePagination {
  margin: 1rem 0 0 0 !important;
  padding: 0 !important;
}
.VuePagination.justify-content-center {
  -webkit-box-pack: end!important;
  -webkit-justify-content: flex-end!important;
  -ms-flex-pack: end!important;
  justify-content: flex-end!important;
}

.VuePagination.justify-content-center .text-center {
  text-align: right !important;
}

.VuePagination__pagination-item-next-chunk,
.VuePagination__pagination-item-prev-chunk {
  display: none !important;
}
</style>