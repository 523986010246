<docs>
## Notes
This is the top level component for the Design Review screen.

This "page" component is simply a wrapper handling component
interactions and compare submission.

A 'design-review' element should encapsulate all children.
Import and define top-level components below if needed.
</docs>

<script>
import { HTTP } from './../http-common';
import { Event } from 'vue-tables-2';
import DesignsTable from './../components/DesignsTable.vue';
import BaselineModal from './../components/BaselineModal.vue';

export default {
  name: 'design-review',

  data () {
     return {
      selectedDesigns: [],
      baseline: null,
      loading: false
     }
  },

  methods: {
    submitSelected(obj) {
      this.loading = true;
      this.baseline = obj.id;
      const ids = this.selectedDesigns.map(row => row.id);

      if(this.baseline && ids.length >= 2) {
        window.location.href = `/projects/compare/${this.baseline}/${ids}`;
      }
    },

    compareSelectedDesigns() {
      if(this.selectedDesigns.length)  {
        try {
          // validate selections
          if(this.selectedDesigns.length < 2 || this.selectedDesigns.length > 4) {
            throw new Error('Please select at least two, and up to four designs to compare.');
          }

          if(this.validStatus() === false) {
            throw new Error('Please only compare completed and production designs.');
          }
          
          // trigger baseline select modal
          $('#modal').modal();
        } 
        catch(e) {
          alert(e);
        }
      }
    },

    updateSelectedDesigns(obj) {
      if(Array.isArray(obj)){
        this.selectedDesigns = obj;
      }
    },

    validStatus() {
      let incompletes = this.selectedDesigns.filter(row => row.status === 'In-Progress');
      return incompletes.length === 0;
    },

    onDesignsTableLoaded(obj) {
      let completes = obj.filter(row => row.status === 'Complete');
      let chosen = obj.filter(row => row.status === 'Production');

      if(completes.length > 1 && chosen.length < 1 && obj[0].user_is_owner === true)
      {
        this.$root.modal = 'chosenDesign';
        $('#help-modal').modal();
      }
    }
  },

  components: {
    DesignsTable,
    BaselineModal
  },

  mounted() {
    Event.$on('updateSelectedDesigns', obj => this.updateSelectedDesigns(obj));
    Event.$on('compareSelectedDesigns', obj => this.compareSelectedDesigns(obj));
    Event.$on('onDesignsTableLoaded', obj => this.onDesignsTableLoaded(obj));
  },

  beforeDestroy() {
    Event.$off(['updateSelectedDesigns']);
  }
}
</script>
