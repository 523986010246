<docs>
## Notes
Displays a list of designs available to the user.
DesignsTable.vue implements the DesignsTableForm component. This is shown when editing the component via the '+' icon. 
We display table data within a v-client-table component.
DesignsTable can remove a design.
DesignsTable can copy a design.
DesignsTable can update the favorite and name properties of a design.
</docs>

<template>
  <div id="designs-table">
    <v-client-table :data="tableData" :columns="columns" :options="options" name="designs-table" ref="designsTable">
      
      <template slot="beforeFilter" >
        <ul class="page-actions VueTables__actions">
          <li><button class="btn btn-primary" v-on:click.prevent="compareSelectedDesigns"><i class="fa fa-th-list fa-fw" aria-hidden="true"></i> Compare Selected Designs</button></li>
          <li v-if="editMode"><a v-bind:href="`/projects/${projectId}/designs/new`" class="btn btn-primary"><i class="fa fa-plus" aria-hidden="true"></i> Add a Design</a></li>
        </ul>
      </template>

      <template slot="afterFilter" >
        <div class="form-group VueTables__search-filter">
          <label class="filter-label">Showing:</label>
          <select class="selectpicker" v-on:change="filterSelect($event)" v-model="filterSelected">
            <option value="my-designs">My Designs</option>
            <option value="favorites">Favorite Designs</option>
            <option value="production">Production Designs</option>
            <option data-divider="true"></option>
            <option value="all">All Designs</option>
          </select>
        </div>
      </template>

      <template slot="h__favorite" slot-scope="props">&nbsp;</template>

      <template slot="favorite" slot-scope="props">
        <a v-if="props.row.favorite" v-on:click.prevent="favoriteDesign({id: props.row.id, favorite: false})">
          <i class="fa fa-star favorite favorite-active" aria-hidden="true"></i>
        </a>
        <a v-if="!props.row.favorite" v-on:click.prevent="favoriteDesign({id: props.row.id, favorite: true})">
          <i class="fa fa-star-o favorite" aria-hidden="true"></i>
        </a>
      </template>

      <template slot="h__compare" slot-scope="props">
          <input type="checkbox" class="checkbox" v-model="allMarked" v-on:change="toggleAll()">
      </template>
      
      <template slot="compare" slot-scope="props">
        <input type="checkbox" class="checkbox" :value="props.row" v-model="markedRows" v-on:change="updateMarkedRows()">
      </template>

      <template slot="project_name" slot-scope="props">
        <a :href="`/projects/${props.row.project_id}?designId=${props.row.id}`">{{props.row.project_name}}</a>
      </template>

      <template slot="name" slot-scope="props">
        <a :href="`/projects/${props.row.project_id}/designs/${props.row.id}?designId=${props.row.id}`">{{props.row.name}}</a>
      </template>

      <template slot="spi_score_total" slot-scope="props">
        <a 
          v-if="props.row.spi_score_total" 
          :href="`/projects/${props.row.project_id}/scores/${props.row.id}`"
          v-bind:class="['badge', 'badge-'+scoreKey(props.row)]">
          <strong>{{props.row.spi_score_total}}</strong>
          {{ scoreKey(props.row)+' norm' }}
        </a>
      </template>

      <template slot="status" slot-scope="props">
        <a href="#status-modal" v-on:click.prevent="statusModal(props.row.status.toLowerCase())">
          <span v-bind:class="['badge', 'badge-'+props.row.status.toLowerCase() ]">{{props.row.status}}</span>
        </a>
      </template>

    </v-client-table>
  </div>
</template>

<script>
import { HTTP } from './../http-common';
import { Event } from 'vue-tables-2';
import DesignsTableForm from './DesignsTableForm.vue'

export default {
  name: 'designs-table',

  props: {
    projectId: Number, 
    reviewMode: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      filterSelected: null,
      markedRows: [],
      allMarked:false,
      
      columns: ['favorite', 'compare', 'id', 'project_name', 'name', 'design_format', 'spi_class', 'spi_score_total', 'updated_at', 'created_by_email', 'status'],

      tableData: [],

      options: {
        childRow: DesignsTableForm,
        childRowTogglerFirst: false,
        headings: {
          id: 'ID',
          project_name: 'Project Name',
          name: 'Design Name',
          design_format: 'Design Format',
          spi_class: 'SPI Class',
          spi_score_total: 'SPI Score',
          updated_at: 'Last Modified',
          created_by_email: 'Created By',
          status: 'Design Status'
        },
        sortable: ['id', 'project_name', 'name', 'design_format', 'spi_class', 'spi_score_total', 'modified', 'updated_at', 'created_by_email', 'status'],
        skin: 'table table-striped',
        sortIcon: {base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort'},
        pagination: {align: 'right'},
        texts:{
          filterPlaceholder: 'Search...'
        },
        rowClassCallback: function(row) {
          return row.user_is_owner === true ? '' : 'locked';
        },
        customFilters: [
          {
            name: 'owner',
            callback: function (row, query) {
                if(query === true) {
                  return row.user_is_owner === true;
                } else {
                  return true;
                }
                
            }
          },
          {
            name: 'favorites',
            callback: function (row, query) {
                if(query === true) {
                  return row.favorite === true;
                } else {
                  return true;
                }
                
            }
          },
          {
            name: 'production',
            callback: function (row, query) {
                if(query === true) {
                  return row.status === 'Production';
                } else {
                  return true;
                }
                
            }
          },
        ],
        orderBy: {
          column: 'updated_at',
          ascending: false
        },
        multiSorting: {
          'updated_at' : [
            {
              column: 'id',
              matchDir: true
            }
          ]
        }
      }
    }
  },

  computed: {
    rowCanEdit() {
      return this.data.user_is_owner;
    }
  },

  methods: {

    // Fetch our designs
    fetchDesigns(){
      let uri = this.projectId ? `/api/projects/${this.projectId}/designs` : '/api/designs'

      HTTP.get(uri)
        .then(response => {
          if(Array.isArray(response.data)){
            this.tableData = response.data;
            Event.$emit('onDesignsTableLoaded', response.data);
          } else {
            throw new Error('DesignTable Component - fetchDesigns - response.data is not an array.');
          }
        })
        .catch(e => {
          alert(e);
        });
    },

    // Create a new design from an existing design ID. Response should return project object.
    // @param {id} string - A design id.
    copyDesign(id){
      if(id)
      {
        HTTP.post(`/api/designs/${id}/copy`)
        .then(response => {
          const clone = response.data;
          if(clone.constructor === Object && Object.keys(clone).length !== 0){
            this.tableData.push(clone);
            this.$refs.designsTable.setOrder('updated_at', false); // re-sort by date descending
            this.$refs.designsTable.setPage(1);
            this.closeAllRows();
          } else {
            throw new Error('DesignsTable Component - copyDesign- response.data is empty or not an object.');
          }
        })
        .catch(e => {
          alert(e);
        });
      }
    },

    // Update a design by ID
    //  @param {obj} obj - An object with updated properties
    //  @param {string} obj.id - A design id.
    //  @param {string} obj.name - The name of a design.
    //  @param {string} obj.project_id - The design’s project id.
    updateDesign(obj){
      if(obj.constructor === Object && Object.keys(obj).length !== 0)
      {
        HTTP.patch(`/api/designs/${obj.id}`, obj)
        .then(response => {
          const index = this.tableData.findIndex(function (row) { return row.id === obj.id; });
          const prevIndex = this.tableData.findIndex(function (row) { return row.chosen === true && row.project_id === obj.project_id; });

          // if previous
          if(response.data.chosen === true && prevIndex !== -1) {
            this.tableData[prevIndex].chosen = false;
            this.tableData[prevIndex].status = 'Complete';
          }

          this.tableData[index].name = response.data.name;
          this.tableData[index].chosen = response.data.chosen;
          this.tableData[index].status = response.data.status;
          this.closeAllRows();
        })
        .catch(e => {
          alert(e);
        });
      }
    },

    // Favorite a design by ID
    //  @param {obj} obj - An object with updated properties
    //  @param {string} obj.id - A design id.
    //  @param {string} obj.favorite - The favorite state of design.
    favoriteDesign(obj){
      if(obj.constructor === Object && Object.keys(obj).length !== 0)
      { 
        const index = this.tableData.findIndex(function (row) { return row.id === obj.id; });

        HTTP.get(`/api/favor/${obj.id}`)
        .then(response => {
          if(response.data.status == 'favored' || response.data.status == 're-favored') {
            this.tableData[index].favorite = true;
          } else {
            this.tableData[index].favorite = false;
          }
        })
        .catch(e => {
          // The request failed, so reset the row to it's previous state.
          this.tableData[index].favorite = !obj.favorite;
          alert(e);
        });
      }
    },
    
    // Delete the project by ID
    // @param {id} string - A project id.
    deleteDesign(id){
      if(id)
      {
        const index = this.tableData.findIndex(function (obj) { return obj.id === id; });

        if(index > -1) {
          HTTP.delete(`/api/designs/${id}`)
          .then(response => {
            this.tableData.splice(index, 1);
          })
          .catch(e => {
            alert(e);
          });
        }
      }
    },

    unmarkAll() {
      this.allMarked = false;
    },
    
    toggleAll() {
      this.markedRows = this.allMarked ? this.$refs.designsTable.data.map(row=>row) : [];
      Event.$emit('updateSelectedDesigns', this.markedRows);
    },

    updateMarkedRows() {
      this.unmarkAll();
      Event.$emit('updateSelectedDesigns', this.markedRows);
    },

    closeAllRows() {
      this.$refs.designsTable.openChildRows.map((val, index) => {
        this.$refs.designsTable.toggleChildRow(val);
      });
    },

    filterSelect(event){
      switch(this.filterSelected) {
        case 'my-designs':
          this.filterMyDesigns(true);
          this.filterFavoriteDesigns(false);
          this.filterProductionDesigns(false);
          break;
        case 'favorites':
          this.filterMyDesigns(false);
          this.filterFavoriteDesigns(true);
          this.filterProductionDesigns(false);
          break;
        case 'production':
          this.filterMyDesigns(false);
          this.filterFavoriteDesigns(false);
          this.filterProductionDesigns(true);
          break;
        case 'all':
        default:
          this.filterMyDesigns(false);
          this.filterFavoriteDesigns(false);
          this.filterProductionDesigns(false);
          break;
      }
    },

    filterMyDesigns(query){
      Event.$emit('vue-tables.designs-table.filter::owner', query);
    },

    filterFavoriteDesigns(query){
      Event.$emit('vue-tables.designs-table.filter::favorites', query);
    },

    filterProductionDesigns(query){
      Event.$emit('vue-tables.designs-table.filter::production', query);
    },

    scoreKey(obj) {
      if(!obj.spi_score_total) return '';
      return obj.spi_score_total < obj.range_low_norm ? 'below' 
           : obj.spi_score_total > obj.range_high_norm ? 'above' : 'meets';
    },

    statusModal(status) {
      this.$root.modal = status;
      $('#status-modal').modal();
    },

    compareSelectedDesigns() {
      Event.$emit('compareSelectedDesigns', this.markedRows);
    }
  },

  beforeMount(){
    this.fetchDesigns();
  },

  mounted() {
    Event.$on('updateDesign', obj => this.updateDesign(obj));
    Event.$on('copyDesign', id => this.copyDesign(id));
    Event.$on('deleteDesign', id => this.deleteDesign(id));

    this.filterSelected = this.reviewMode ? 'all' : 'my-designs';
    this.filterSelect();

    this.$nextTick(function () {
      // bootstrap select
      $('.selectpicker').selectpicker();
    });
  },

  beforeDestroy() {
    Event.$off(['updateDesign', 'copyDesign', 'deleteDesign']);
  }
}
</script>



<style>
.VueTables__heading .checkbox {
  display: inline-block;
  margin-right: 5px;
}

.VueTables .favorite {
  font-size: 18px;
  position: relative;
  top: 2px;
  color: #777;
  opacity: 1;
}

.VueTables .favorite-active {
  color: #fdd700;
}

.VueTables tr.locked .VueTables__child-row-toggler {
  pointer-events: none;
}

.VueTables tr.locked .VueTables__child-row-toggler--closed::before,
.VueTables tr.locked .VueTables__child-row-toggler--open::after {
  pointer-events: none;
  content: '\f023';
  opacity: 0.35;
}

.VueTables tr.locked + tr.VueTables__child-row {
  display: none !important;
}

</style>