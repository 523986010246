<docs>
## Notes
This is a top level component. It is used as the main parent component 
throughout the app, unless a page-specific parent component is required.

An 'app' element should encapsulate all children.
Import and define top-level components below.
</docs>

<script>
import ProjectsTable from './components/ProjectsTable.vue'
import DesignsTable from './components/DesignsTable.vue'
import ExistingDesignsTable from './components/ExistingDesignsTable.vue'
import PackagingComponents from './components/PackagingComponents.vue'

export default {
  name: 'app',
  data () {
     return {}
  },

  components: {
    ProjectsTable,
    DesignsTable,
    ExistingDesignsTable,
    PackagingComponents
  },

  beforeMount(){
  }
}
</script>
