<docs>
## Notes
Displays a corrugated paper mass calclator.
Emits "updateMass" event with mass value.
Converts units based on user prefs.
Repopulates inputs with stored data if prop is passed.

Formula: Calculater v1.3.xlxs

  length_cm * width_cm * lookup(density)

    density - (gram per cm2) based on strength (x / x ECT dropdown)
    flute does not affect calculation
</docs>

<template>
  <div class="card card-info mt-3">
    <div class="card-header">
      <h3 class="card-title">Corrugated Calculator</h3>
    </div>
    <div class="card-body">
      <div class="form-group">
        <label>Length</label>
        <div>
          <input type="number" class="form-control input-narrow" name="length" v-model="length">
          <span class="text-muted" v-if="user.units == 'in'">inches</span>
          <span class="text-muted" v-if="user.units == 'cm'">cm</span>
        </div>
      </div>
      <div class="form-group">
        <label>Width</label>
        <div>
          <input type="number" class="form-control input-narrow" name="width" v-model="width">
          <span class="text-muted" v-if="user.units == 'in'">inches</span>
          <span class="text-muted" v-if="user.units == 'cm'">cm</span>
        </div>
      </div>
      <div class="form-group">
        <label>Flute</label><br>
        <select class="form-control selectpicker select-narrow" v-model="flute">
          <option disabled value="">Please select one</option>
          <option v-for="option in options.flute" :value="option.name">{{ option.name }}</option>
        </select> 
      </div>
      <div class="form-group">
        <label>Type</label><br>
        <select class="form-control selectpicker select-narrow" v-model="strength">
          <option disabled value="">Please select one</option>
          <option v-for="option in options.material_strength" :value="option.name">{{ option.name }}</option>
        </select> 
      </div>
      <div class="form-group">
        <label>Mass</label><br>
        <input type="number" class="form-control input-narrow" name="mass" v-model="mass" readonly>
        <span class="text-muted">grams</span>
      </div>

      <button type="button" class="btn btn-info btn-sm" @click.prevent="computeMass" v-bind:disabled="!isValid">
        Calculate mass
      </button>
      <button type="button" class="btn btn-info btn-sm" @click.prevent="updateMass" v-bind:disabled="!isValid">
        Use this mass
      </button>
    </div>
  </div>
</template> 

<script>
import * as constants from './../constants-common.js';

export default {
  name: 'corrugated-mass-calculator',

  props: ['calculatorData'],

  data () {
     return {
      length: 0,
      width: 0,
      flute: null,
      strength: null,
      mass: null,
      units: this.user.units,
      options: {
        material_strength: constants.CALCULATOR.material_strength,
        flute: constants.CALCULATOR.flute
      }
     }
  },

  computed: {
    isValid: function() {
      return (this.length > 0 && this.width > 0 && this.strength);
    }
  },

  methods: {
    computeMass(){
      let length = this.length;
      let width  = this.width;
      let strength = this.lookup(this.options.material_strength, 'name', this.strength);
      //let flute = this.lookup(this.options.flute, 'name', this.flute);

      if(this.user.units === 'in') {
        // convert in to cm for calculation
        length = this.round2(this.length * 2.54);
        width  = this.round2(this.width * 2.54);
      }

      this.mass = Number(this.round2((length*width)*strength));
    },

    updateMass(){
      this.$emit('useCalculatedMass', this.getCalculatorData());
    },

    getCalculatorData(){
      return {
        length: this.length,
        width: this.width,
        flute: this.flute,
        strength: this.strength,
        mass: this.mass,
        units: this.units
      }
    },

    setCalculatorData(){
      let obj = this.calculatorData;

      if(obj === null || typeof obj !== 'object') {
        return;
      }

      // restore calculated values, then check for conversions
      this.length = obj.length;
      this.width = obj.width;
      this.flute = obj.flute;
      this.strength = obj.strength;
      this.mass = obj.mass;

      if(obj.units == 'in' && this.user.units === 'cm') {
        // convert in to cm
        this.length = this.round2(obj.length * 2.54);
        this.width  = this.round2(obj.width * 2.54);
      }

      if(obj.units == 'cm' && this.user.units === 'in') {
        // convert cm to in
        this.length = this.round2(obj.length/ 2.54);
        this.width  = this.round2(obj.width / 2.54);
      }
    },

    round2(input){
      return Math.round(input * 100) / 100;
    },

    lookup(optionsArray, key, val){
      let option = optionsArray.find(obj => obj[key] === val);
      return option.value;
    }
  },

  mounted(){
    this.$nextTick(function () {
      // bootstrap select
      $('.selectpicker').selectpicker();
    });
  },

  beforeMount(){
    this.setCalculatorData();
  }
}
</script>
