<docs>
## Notes
Displays a list of packaging components attached to the design.
PackagingComponents.vue implements the ComponentLibrary and ComponentForm components.
PackagingComponents recieves a "componentSelected" event fom ComponentLibrary, attaching components to the design.
PackagingComponents can remove a component from the design.
PackagingComponents load a component's edit form.

## Props
PackagingComponents requires projectId and designId props. This specifies the ID of the project design to fetch and update.
</docs>

<template>
  <div id="packaging-components-table" class="mb-5">
    <v-client-table :data="tableData" :columns="columns" :options="options" name="packaging-components" ref="componentsTable">
      <template slot="actions" slot-scope="props">
        <div class="text-right" v-if="userIsOwner">
          <button type="button" class="btn btn-light btn-sm" v-on:click.prevent="handleEditDesignComponent(props.row)">Edit</button>
          <button type="button" class="btn btn-light btn-sm" v-on:click.prevent="handleRemoveDesignComponent(props.row)">Delete</button>
        </div>
      </template>
      <template slot="mass_g" slot-scope="props">
        {{ round6(props.row.mass_g) }}
      </template>
      <template slot="calculated" slot-scope="props">
        {{ props.row.calculated ? 'Calculated' : 'Entered' }}
      </template>
    </v-client-table>

    <div id="components-editor"></div>
    
    <div class="card card-default mt-2">
      <div class="card-header">
        <h2 class="card-title">{{ editingComponentData ? 'Edit' : 'Add a' }} Component</h2>
      </div>

      <div class="card-body packaging-component-buttons" v-if="userIsOwner">
        <button type="button" v-bind:class="[(currentView === 'paper' ? 'active' : null), 'btn', 'btn-light']" @click.prevent="setView('paper')">Paper</button>
        <button type="button" v-bind:class="[(currentView === 'plastic' ? 'active' : null), 'btn', 'btn-light']" @click.prevent="setView('plastic')">Plastic</button>
        <button type="button" v-bind:class="[(currentView === 'other' ? 'active' : null), 'btn', 'btn-light']" @click.prevent="setView('other')">Other</button>
        <button type="button" v-bind:class="[(currentView === 'library' ? 'active' : null), 'btn', 'btn-light']" @click.prevent="setView('library')">Component Library</button>
      </div>

      <!--  
        Note that we use v-if to create and destroy the component - not just visually hide it. 
        This forces `component-library` to refire any api calls within it's lifecycle hooks.
        Multiple instances of `packaging-component-form` have a unique :key added which also 
        forces Vue to replace instead of reuse the component and trigger it's lifecycle hooks.
      -->
      <div v-if="currentView === 'paper'">
        <component-form
          v-on:addDesignComponent="handleAddDesignComponent" 
          v-on:hideComponentForm="handleHideComponentForm"
          :editData="editingComponentData"
          :editLoading="loadingComponent"
          :currentView="currentView"
          :key="currentView"
          ref="paperComponent">
        </component-form>
      </div>

      <div v-if="currentView === 'plastic'">
        <component-form
          v-on:addDesignComponent="handleAddDesignComponent" 
          v-on:hideComponentForm="handleHideComponentForm"
          :editData="editingComponentData"
          :editLoading="loadingComponent"
          :currentView="currentView"
          :key="currentView"
          ref="plasticComponent">
        </component-form>
      </div>

      <div v-if="currentView === 'other'">
        <component-form 
          v-on:addDesignComponent="handleAddDesignComponent" 
          v-on:hideComponentForm="handleHideComponentForm"
          :editData="editingComponentData"
          :editLoading="loadingComponent"
          :currentView="currentView"
          :key="currentView"
          ref="otherComponent">
        </component-form>
      </div>

      <div v-if="currentView === 'library'">
          <!-- Note: We use the referece this.$refs.library.unmarkAll(); to clear the selected rows -->
          <component-library 
            v-on:componentSelected="handleAddComponentsToDesign" 
            ref="library">
          </component-library>
      </div>

    </div><!-- /.card -->
    
  </div>
</template>

<script>
import { HTTP } from './../http-common';
import { Event } from 'vue-tables-2';
import ComponentForm from './ComponentForm.vue';
import ComponentLibrary from './ComponentLibrary.vue'

export default {
  name: 'packaging-components',

  props: {
    // the id of the project
    // @example - this.projectId
    projectId: {
      type: Number,
      default: null,
      required: true
    },
    // the id of the design
    // @example - this.designId
    designId: {
      type: Number,
      default: null,
      required: true
    },
    userIsOwner: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      // dynamic switching of components
      // @default {string} [paper, plastic, other, library]
      currentView: null,

      // editing existing component
      editingComponentData: null,
      loadingComponent: false,

      // v-client-table column identifiers
      columns: ['name', 'material', 'mass_g', 'calculated', 'actions'],

      // v-client-table data
      tableData: [],

      // v-client-table options
      options: {
        headings: {
          select: false,
          name: 'Component Name',
          material: 'Material Type',
          mass_g: 'Mass (g)',
          calculated: 'Data Source',
          actions: false
        },
        filterable: false,
        sortable: ['name', 'material', 'mass_g', 'calculated'],
        skin: 'table table-striped',
        sortIcon: {base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort'},
        pagination: {align: 'right'},
        perPage: 999,
        orderBy: {
          column: 'updated_at',
          ascending: false
        },
        multiSorting: {
          'updated_at' : [
            {
              column: 'id',
              matchDir: true
            }
          ]
        }
      }
    }
  },

  methods: {
    // Click handler to set the active component form.
    //  @param {string} view - [paper, plastic, other, library]
    setView(view){
      this.editingComponentData = null;

      if (view == this.currentView) {
        this.currentView = null;
      } else {
        this.currentView = view;
        Spi.common.scrollTo('#components-editor');
      }
    },

    // Fetch our project's components.
    fetchDesignComponents(){
      HTTP.get(`/api/design/${this.designId}/components`)
        .then(response => {
          this.tableData = response.data;
          Event.$emit('packaging.componentsUpdated', this.tableData);
        })
        .catch(e => {
          alert(e);
        });
    },

    // Hide the current component forms.
    handleHideComponentForm(){
      this.setView(null);
    },

    // Click handler to add a component to the design or update an existing component.
    //  @param {object} obj - the design component model [paper, plastic, other].
    handleAddDesignComponent(obj){
      if(obj !== null && typeof obj === 'object') {
        let data = obj;
        data.parent_project_id = this.projectId;
        data.parent_design_id  = this.designId;
        const uri = data.id ? `/api/components/${data.id}` : '/api/components';
        this.loadingComponent = true;

        // save the component directly passing in the parent ids.
        HTTP[data.id ? 'patch' : 'post'](uri, data)
          .then(response => {
            if(data.id) {
              // update existing component row
              const index = this.tableData.findIndex(function (row) { return row.id === obj.id; });
              this.tableData.splice(index, 1, response.data);
            } else {
              // add new component to table
              this.tableData.push(response.data);
            }
            Event.$emit('packaging.componentsUpdated', this.tableData);
            this.loadingComponent = false;
            this.setView(null);
          })
          .catch(e => {
            alert(e);
            this.loadingComponent = false;
          });
      }
    },

    // Click handler to add multiple components to the design.
    // This is called by the component library event.
    //  @param {array} ids - an array of component library ids.
    handleAddComponentsToDesign(ids){
      if(Array.isArray(ids) && ids.length >= 1){
        const data = { 
          parent_project_id: this.projectId, 
          parent_design_id: this.designId, 
          ids: ids
        };
        HTTP.post(`/api/components/copy`, data)
        .then(response => {
          this.tableData = [...this.tableData, ...response.data];
          this.$refs.library.resetSelected();
          Event.$emit('packaging.componentsUpdated', this.tableData);
          this.setView(null);
        })
        .catch(e => {
          alert(e);
        });
      }
    },

    // Click handler to remove a packaging component from the project
    //  @param {Object} obj - a packaging component object.
    handleRemoveDesignComponent(obj){
      if(window.confirm(`Do you want to remove the "${obj.name}" component?`)) this.removeDesignComponent(obj.id);
    },

    // Removes a packaging component
    //  @param {string} id - A projects component id.
    removeDesignComponent(id){
      const index = this.tableData.findIndex(function (obj) { return obj.id === id; });
      
      if(index > -1) {
        HTTP.delete(`/api/components/${id}`)
        .then(response => {
          console.log(response);
          this.tableData.splice(index, 1);
        })
        .catch(e => {
          alert(e);
        });
      }
    },

    handleEditDesignComponent(obj) {
      this.setView(null);

      switch(obj.material_conversion_entry_type) {
        case 'Paper':
          this.setView('paper');
          this.editingComponentData = obj;
          break;
        case 'Plastic':
          this.setView('plastic');
          this.editingComponentData = obj;
          break;
        case 'Other':
          this.setView('other');
          this.editingComponentData = obj;
          break;
        default:
          return null;
      }
    },

    round2(input){
      return Math.round(input * 100) / 100;
    },

    round6(input){
      return Math.round(input * 1000000) / 1000000;
    }
  },

  // fecth our design's components.
  beforeMount(){
    this.fetchDesignComponents();
  },

  // Child components
  components: {
    ComponentLibrary,
    ComponentForm
  }
}
</script>

<style>
#packaging-components-table .VueTables--client {
  margin-bottom: 0;
}
#packaging-components-table .VuePagination {
  display: none;
}
</style>