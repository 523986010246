"use strict";

module.exports = function (key, value) {

  if (!this.opts.saveState || !this.activeState) return;

  try {
    var currentState = JSON.parse(this.storage.getItem(this.stateKey));
  } catch (e) {
    var currentState = this.initState();
  }

  currentState[key] = value;

  this.storage.setItem(this.stateKey, JSON.stringify(currentState));
};