export const SUB_TYPES = {
  paper: [
    {id: 1,  name: 'Folded Carton (Paperboard)', material: 10, conversion: 9, calc: 'paper'},
    {id: 2,  name: 'Folded Carton (Corrugated)', material: 3, conversion: 10, calc: 'corrugated'},
    {id: 3,  name: 'Insert/Cut-Out/Component (Paperboard)', material: 10, conversion: 11, calc: 'paper'},
    {id: 4,  name: 'Insert/Cut-Out/Component (Corrugated)', material: 3, conversion: 11, calc: 'corrugated'},
    {id: 5,  name: 'Paper Ties', material: 4, conversion: 11},
    {id: 6,  name: 'Stickers', material: 6, conversion: 11, calc: 'paper'},
    {id: 7,  name: 'Tissue Paper', material: 4, conversion: 11, calc: 'paper'},
    {id: 8,  name: 'Unlisted Paper Component', material: 11, conversion: 13, calc: 'paper'},
    {id: 9,  name: 'Backer/Blister Card (Paperboard)', material: 10, conversion: 11, calc: 'paper'},
    {id: 10, name: 'Molded Pulp', material: 1, conversion: 17}
  ],
  plastic: [
    {id: 11, name: 'Blister (PET)', material: 15, conversion: 1, calc: 'blister'},
    {id: 12, name: 'Clear Plastic Elastics'},
    {id: 13, name: 'Dennison Tags/Ties', material: 13, conversion: 6},
    {id: 14, name: 'J-Hook'},
    {id: 15, name: 'Plastic Fasteners (Rigid)', material: 19, conversion: 6},
    {id: 16, name: 'Blister (PVC)', material: 24, conversion: 1, calc: 'blister'},
    {id: 17, name: 'Plastic Fasteners/Straps(Flexible/Clear)', material: 24, conversion: 3},
    {id: 18, name: 'Polybag', material: 18, conversion: 3, calc: 'polybag'},
    {id: 19, name: 'Shrink Wrap', calc: 'flat'},
    {id: 20, name: 'Window/Flat Plastic Component', material: 24, conversion: 3, calc: 'flat'},
    {id: 21, name: 'Zip ties', material: 13, conversion: 6},
    {id: 22, name: 'Unlisted Plastic Component', material: 34},
    {id: 23, name: 'Flexible - Paper-foil Pouch', material: 28, conversion: 4, calc: 'flat'},
    {id: 24, name: 'Flexible - Poly-foil Pouch', material: 27, conversion: 4, calc: 'flat'},
    {id: 25, name: 'Flexible - PE Dominant', material: 28, conversion: 4, }
  ],
  other: [
    {id: 26, name: 'Other'},
    {id: 27, name: 'Aluminum Foil', material: 29, conversion: 14, calc: 'flat'},
    {id: 28, name: 'Plastic Coated Wire', material: 31, conversion: 17},
    {id: 29, name: 'String', material: 30, conversion: 17},
    {id: 30, name: 'Mesh Bag'}
  ]
};

export const MATERIALS = {
  paper: [
    {id: 1, name: 'Molded Pulp'},
    //{id: 2, name: 'Cardboard'}, // not used
    {id: 3, name: 'Corrugated'},
    {id: 4, name: 'Kraft (bleached)'},
    {id: 5, name: 'Kraft (unbleached)'},
    {id: 6, name: 'Paper (coated)'},
    {id: 7, name: 'Paper (uncoated)'},
    {id: 8, name: 'SBB'},
    {id: 9, name: 'SUB'},
    {id: 10, name: 'CCNB/Chipboard'},
    {id: 11, name: 'Other - Paper'}
  ],
  plastic: [
    {id: 26, name: 'Flexible - Paper-foil Pouch'},
    {id: 14, name: 'Polyester'},
    {id: 28, name: 'Flexible - PE Dominant'},
    {id: 12, name: 'ABS'},
    {id: 13, name: 'Nylon'},
    {id: 15, name: 'PET'},
    {id: 16, name: 'HDPE'},
    {id: 17, name: 'LDPE'},
    {id: 18, name: 'LLDPE'},
    {id: 19, name: 'PP'},
    {id: 20, name: 'PS (General)'},
    {id: 21, name: 'PS (High Impact)'},
    {id: 22, name: 'PUR (Flexible Foam)'},
    {id: 23, name: 'PUR (Rigid Foam)'},
    {id: 24, name: 'PVC'},
    {id: 25, name: 'PVDC'},
    {id: 27, name: 'Polyfoil Pouch'},
    {id: 34, name: 'Other-Resin'}
  ],
  other: [
    {id: 35, name: 'Other'},
    {id: 29, name: 'Aluminum Foil'},
    {id: 30, name: 'Cotton'},
    {id: 31, name: 'Resin Coated Wire'},
    {id: 32, name: 'Synthetic Rubber'}
    //{id: 33, name: 'Elec'} // not used
  ]
};

export const CONVERSION = {
  paper: [
    {id: 10, name: 'Production Corrugated Carton'},
    {id: 9,  name: 'Production Carton'},
    {id: 11, name: 'Paper Cutting'},
    {id: 12, name: 'Production Paper Bags'},
    {id: 13, name: 'Average Paper'},
    {id: 16, name: 'No Conversion'},
    {id: 17, name: 'Conversion Included'}
  ],
  plastic: [
    {id: 8,  name: 'Other-Resin'},
    {id: 1,  name: 'Extrusion Thermoform'},
    {id: 2,  name: 'Blow Molding'},
    {id: 3,  name: 'Film Extrusion'},
    {id: 4,  name: 'Flexible'},
    {id: 5,  name: 'Foaming Expanding'},
    {id: 6,  name: 'Injection Molding'},
    {id: 7,  name: 'Stretch Blow Molding'},
    {id: 16, name: 'No Conversion'},
    {id: 17, name: 'Conversion Included'}
  ],
  other: [
    {id: 15, name: 'Other'},
    {id: 14, name: 'Aluminum Rolling'},
    {id: 16, name: 'No Conversion'},
    {id: 17, name: 'Conversion Included'}
  ]
};

export const CERTIFICATION = [ 
  {id: 1,  name: 'None'},
  {id: 2,  name: 'FSC'},
  {id: 3,  name: 'PEFC'},
  {id: 4,  name: 'SFI'},
  {id: 5,  name: 'CSA'},
  {id: 6,  name: 'MTCC'},
  {id: 7,  name: 'AFS'},
  {id: 8,  name: 'Cerflor'},
  {id: 9,  name: 'CERTFOR'},
  {id: 10, name: 'CFCS'}
];

export const CALCULATOR = {
  material_strength: [
    {name: '125 / 23 ECT single', value: '0.051753691'},
    {name: '150 / 26 ECT single', value: '0.056147872'},
    {name: '175 / 29 ECT single', value: '0.060542053'},
    {name: '200 / 32 ECT single', value: '0.073724597'}
  ],
  flute: [
    {name: 'B', value:'1'},
    {name: 'C', value:'1'},
    {name: 'E', value:'1'}
  ],
  component_mass : [
    // these names should match SUB_TYPES names as keys
    {id: 12, name: 'Clear Plastic Elastics', value: '0.1'},
    {id: 13, name: 'Dennison Tags/Ties', value : '0.005'},
    {id: 14, name: 'J-Hook', value : '1'},
    {id: 28, name: 'Plastic Coated Wire', value : '0.04'},
    {id: 5,  name: 'Paper Ties', value : '0.5'}
  ],
  material_density: [
    // these ids should match MATERIALS ids
    {id: 12, name: 'ABS', value: '1.07'},
    {id: 13, name: 'Nylon', value: '1.15'},
    {id: 14, name: 'Polyester', value: '0.95' },
    {id: 15, name: 'PET', value: '1.345'},
    {id: 16, name: 'HDPE', value: '0.95'},
    {id: 17, name: 'LDPE', value: '0.95'},
    {id: 18, name: 'LLDPE', value: '0.95'},
    {id: 19, name: 'PP', value: '0.9'},
    {id: 21, name: 'PS (High Impact)', value: '1.045'},
    {id: 20, name: 'PS (General)', value: '1.045'},
    //{id: 0, name: 'EPS', value: '0.015' },
    {id: 22, name: 'PUR (Flexible Foam)', value: '1.2' },
    {id: 23, name: 'PUR (Rigid Foam)', value: '1.2' },
    {id: 24, name: 'PVC', value: '1.38'},
    {id: 25, name: 'PVDC', value: '1.44'},
    {id: 26, name: 'Flexible - Paper-foil Pouch', value: '0.922'},
    {id: 27, name: 'Flexible - Poly-foil Pouch', value: '0.922'},
    {id: 27, name: 'Polyfoil Pouch', value: '0.922'},
    {id: 28, name: 'Flexible - PE Dominant', value: '0.922'},
    {id: 34, name: 'Other-Resin', value: '1.081'},
    {id: 29, name: 'Aluminum Foil', value: '2.7'}
  ],
  conversions: {
    blisterloss: '0.5',
    cm2toin2: '0.15500031',
    in2tocm2: '6.4516',
    mmtomil: '39.3700787',
    miltomm: '0.0254'
  }
};

export const PROJECT_OPTIONS = {
  'toy' : [
    {id: 1,  name:'Plush'},
    {id: 2,  name:'Dolls and Figures'},
    {id: 3,  name:'Roleplay and Costumes'},
    {id: 4,  name:'Cards, Games, and Puzzles'},
    {id: 5,  name:'Musical'},
    {id: 6,  name:'Ride-Ons'},
    {id: 7,  name:'Vehicles'},
    {id: 8,  name:'Outdoor, Sport, and Camp'},
    {id: 9,  name:'Electronics'},
  ],
  'toy_acc' : [
    {id: 1,  name:'Plush'},
    {id: 2,  name:'Dolls and Figures'},
    {id: 3,  name:'Roleplay and Costumes'},
    {id: 4,  name:'Cards, Games, and Puzzles'},
    {id: 5,  name:'Musical'},
    {id: 6,  name:'Ride-Ons'},
    {id: 7,  name:'Vehicles'},
    {id: 8,  name:'Outdoor, Sport, and Camp'},
    {id: 9,  name:'Electronics'},
    {id: 10, name:'Arts and Crafts'},
    {id: 11, name:'Playsets'},
    {id: 12, name:'Building Sets'},
    {id: 13, name:'Other'}
  ],
  'multi' : [
    {id: 1,  name:'Plush'},    
    {id: 11, name:'Playsets'},
    {id: 2,  name:'Dolls and Figures'},
    {id: 4,  name:'Cards, Games, and Puzzles'},
    {id: 10, name:'Arts and Crafts'}
  ],
  'playsets' : [
    {id: 12, name:'Building Sets'},
    {id: 7,  name:'Vehicles'},
    {id: 5,  name:'Musical'},
    {id: 8,  name:'Outdoor, Sport, and Camp'},
    {id: 13, name:'Other'}
  ]
};