<template>
  <div id="modal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Select Baseline</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>ID</th>
                  <th>Project Name</th>
                  <th>Description</th>
                  <th>Design Format</th>
                  <th>SPI Score</th>
                  <th>Last Modified</th>
                  <th>Created By</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in selectedDesigns">
                  <td><input type="radio" name="baseline" :value="row.id" v-model="baseline"></td>
                  <td>{{ row.id }}</td>
                  <td>{{ row.project_name }}</td>
                  <td>{{ row.name }}</td>
                  <td>{{ row.design_format }}</td>
                  <td>
                    <span 
                      v-if="row.spi_score_total" 
                      v-bind:class="['badge', 'badge-'+scoreKey(row)]">
                      <strong>{{row.spi_score_total}}</strong>
                      {{ scoreKey(row)+' norm' }}
                    </span>
                  </td>
                  <td>{{ row.updated_at }}</td>
                  <td>{{ row.created_by_email }}</td>
                  <td><span v-bind:class="['badge', 'badge-'+row.status.toLowerCase() ]">{{row.status}}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" 
            v-on:click.prevent="handleSubmit"
            v-bind:disabled="loading || !baseline">
            <span v-if="loading">Loading...</span>
            <span v-else>Compare selected designs</span>
          </button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->
</template>

<script>

export default {
  name: 'baseline-modal',

  props: {
    selectedDesigns: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      baseline: null
    }
  },

  methods: {
    handleSubmit() {
      this.$emit('submit-selected', { id: this.baseline });
    },

    scoreKey(obj) {
      if(!obj.spi_score_total) return '';
      return obj.spi_score_total < obj.range_low_norm ? 'below' 
           : obj.spi_score_total > obj.range_high_norm ? 'above' : 'meets';
    }
  }
}
</script>