"use strict";

module.exports = function () {
  var columns = Object.keys(this.opts.listColumns);

  var res = {};

  columns.forEach(function (column) {
    res[column] = {};

    this.opts.listColumns[column].forEach(function (item) {
      res[column][item.id] = item.text;
    });
  }.bind(this));

  return res;
};